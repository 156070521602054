import { useLocation, useNavigate } from "react-router-dom";
import back from "../../../assets/images/ic_arrow-left.svg";
import CartItem from "../components/cart_item";
import CartSummary from "../components/cart_summary";
import EmptyCart from "../components/empty_state";
import CartSection from "../components/cart_section";
import OrderSummary from "../components/order_summary";
import ProductModel from "../../product/models/product_model";
import { useEffect, useState } from "react";
import Switch from "../../shared/components/switch";
import Radio from "../../shared/components/radio";
import TextInput from "../../shared/components/text_input";
import BottomSheet from "../../shared/components/bottom_sheet";
import ShippingOptions from "./shipping_options";
import routes from "../../../routes";
import addressFacade from "../facades/address_facade";
import { AddressModel } from "../models/address_model";
import { Cart } from "../models/cart_model";
import { CourierModel } from "../models/courier_model";
import mamaPointFacade from "../facades/mama_point_facade";
import settingFacade from "../facades/setting_facade";
import orderFacade from "../facades/order_facade";
import { MembershipModel } from "../models/membership_model";
import authFacade from "../../authentication/facades/auth_facade";
import Toast from "../../../utils/toast";

function CheckoutPage() {
  const location = useLocation();
  const carts = location.state.carts as Cart[];
  const { addresses, fetchAddress } = addressFacade();
  const { mamaPoint, fetchMamaPoint } = mamaPointFacade();
  const { setting, fetchSetting } = settingFacade();
  const { courierFee, fetchCourierFee, fetchCourierFeeGuest } = orderFacade();
  const { isAuthenticated } = authFacade();

  const navigate = useNavigate();
  const [isDropship, setIsDropship] = useState<boolean>(
    localStorage.getItem("isDropship") == "true"
  );

  const [dropShipName, setDropShipName] = useState<string>(
    localStorage.getItem("dropShipName") ?? ""
  );
  const [dropShipPhone, setDropShipPhone] = useState<string>(
    localStorage.getItem("dropShipPhone") ?? ""
  );

  const [addressDialog, setAddressDialog] = useState(false);
  const [isShippingVisible, showShipping] = useState(false);
  const [useMamaPoint, setUseMamaPoint] = useState<boolean>(
    localStorage.getItem("useMamaPoint") == "true"
  );
  const [subtotal, setSubtotal] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);
  const [appFee, setAppFee] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [cashback, setCashback] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [mamaPointPrice, setMamaPointPrice] = useState(0);

  const [selectedAddress, setSelectedAddress] = useState<AddressModel>(
    JSON.parse(localStorage.getItem("address") ?? "{}") as AddressModel
  );
  const [selectedShipping, setSelectedShipping] = useState<CourierModel>(
    JSON.parse(localStorage.getItem("courier") ?? "{}") as CourierModel
  );

  const [selectedVoucher, setSelectedVoucher] = useState<MembershipModel>(
    JSON.parse(localStorage.getItem("voucher") ?? "{}") as MembershipModel
  );

  // First Fetch Address, Mama Point, And Setting (Application Fee and detect if has storage process it)
  useEffect(() => {
    if (isAuthenticated) {
      fetchAddress();
      fetchMamaPoint();
    }
    fetchSetting();

    let price = 0;
    carts.forEach((cart) => {
      if (cart?.product?.price && cart?.qty) {
        price += cart.product.price * cart.qty;
      }
    });

    if (Object.keys(selectedShipping).length != 0) {
      if (isAuthenticated) {
        let cartIds = carts.map((cart) => cart.id) as number[];
        fetchCourierFee(
          [selectedShipping?.id ?? 0],
          cartIds,
          selectedAddress?.id ?? 0
        );
        try {
          setShippingFee(courierFee.pricing![0].price ?? 0);
        } catch (error) {
          Toast.show("Kurir tidak tersedia", {});
          setSelectedShipping({});
        }
      } else {
        fetchCourierFeeGuest({
          courier_ids: [selectedShipping?.id ?? 0],
          address: selectedAddress,
          carts: carts,
        });
        try {
          setShippingFee(courierFee.pricing![0].price ?? 0);
        } catch (error) {
          Toast.show("Kurir tidak tersedia", {});
          setSelectedShipping({});
        }
      }
    }

    if (Object.keys(selectedVoucher).length != 0) {
      setDiscount(selectedVoucher.voucher?.value ?? 0);
    }

    if (useMamaPoint === true) {
      setUseMamaPoint(true);
    }

    setSubtotal(price);
    setAppFee(parseInt(setting.application_fee ?? "0") ?? 0);

    localStorage.setItem("carts", JSON.stringify(carts));
  }, []);

  // UseEffect When any price changed
  useEffect(() => {
    setTotalPrice(
      subtotal + shippingFee + appFee - discount - cashback - mamaPointPrice
    );
  }, [subtotal, shippingFee, appFee, discount, cashback, mamaPointPrice]);

  // UseEffect When Mama Point Changed
  useEffect(() => {
    if (useMamaPoint) {
      if (mamaPoint.data?.length != 0) {
        setMamaPointPrice(mamaPoint.data![0].point_final ?? 0);
      }
    } else {
      setMamaPointPrice(0);
    }
  }, [useMamaPoint]);

  // Handler Courier
  const handlerCourier = async () => {
    showShipping(false);

    if (isAuthenticated) {
      let cartIds = carts.map((cart) => cart.id) as number[];
      await fetchCourierFee(
        [selectedShipping?.id ?? 0],
        cartIds,
        selectedAddress?.id ?? 0
      );

      try {
        setShippingFee(courierFee.pricing![0].price ?? 0);
      } catch (error) {
        Toast.show("Kurir tidak tersedia", {});
        setSelectedShipping({});
      }
    } else {
      await fetchCourierFeeGuest({
        courier_ids: [selectedShipping?.id ?? 0],
        address: selectedAddress,
        carts: carts,
      });
      try {
        setShippingFee(courierFee.pricing![0].price ?? 0);
      } catch (error) {
        Toast.show("Kurir tidak tersedia", {});
        setSelectedShipping({});
      }
    }
    localStorage.setItem("courier", JSON.stringify(selectedShipping));
  };

  // Handler Address
  const handleAddress = async (address: AddressModel) => {
    setSelectedAddress(address);
    localStorage.setItem("address", JSON.stringify(address));
  };

  const handlePayment = () => {
    navigate(routes.paymentMethod, {
      state: {
        subtotal,
        shippingFee,
        appFee,
        discount,
        cashback,
        mamaPointPrice,
        totalPrice,
      },
    });
  };

  // Handler Voucher
  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  return (
    <div className="cart-page">
      <div className="header">
        <img
          src={back}
          alt=""
          className="back-btn"
          onClick={() => navigate(-1)}
        />
        <div className="title">Checkout</div>
      </div>
      <div className="content" style={{ paddingBottom: 150 }}>
        <CartSection
          title="Alamat Pengiriman"
          text={
            selectedAddress?.label ??
            selectedAddress.receiver_name ??
            (isAuthenticated ? "Pilih alamat" : "Atur alamat")
          }
          onTap={() => {
            if (isAuthenticated) {
              setAddressDialog(true);
            } else {
              navigate(routes.addAddress, {
                state: { fromCheckout: true, carts: location.state.carts },
              });
            }
          }}
        />
        <div className="items">
          {carts.length == 0 ? (
            <EmptyCart></EmptyCart>
          ) : (
            carts.map((cart, i) => (
              <CartItem
                needWishlist={true}
                cart={cart}
                key={cart.id}
                totalItems={cart.qty}
              ></CartItem>
            ))
          )}
        </div>
        <CartSection
          title="Metode Pengiriman"
          text={selectedShipping?.name ?? "Pilih opsi pengiriman"}
          onTap={() =>
            selectedAddress ? showShipping(true) : showShipping(false)
          }
        />
        {isAuthenticated && (
          <CartSection
            title="Voucher Belanja"
            text={
              Object.keys(selectedVoucher).length != 0
                ? "Kamu Hemat " + selectedVoucher.voucher?.value_text_formatted
                : "Pilih voucher belanja"
            }
            onTap={() => navigate(routes.voucher)}
          />
        )}
        {isAuthenticated && (
          <CartSection
            title="Pakai Mama Poin"
            text={
              "Rp " +
              numberWithCommas(
                (mamaPoint.data?.length ?? 0) == 0
                  ? 0
                  : mamaPoint.data![0].point_final ?? 0
              )
            }
            prefix={<div className="gems"></div>}
            suffix={
              <Switch
                checked={useMamaPoint}
                onChange={(val) => {
                  localStorage.setItem("useMamaPoint", val.toString());
                  setUseMamaPoint(val);
                }}
              />
            }
          />
        )}
        <OrderSummary
          data={
            new Map([
              ["Sub Total", "Rp. " + numberWithCommas(subtotal)],
              ["Ongkos Kirim", "Rp. " + numberWithCommas(shippingFee)],
              ["Biaya Aplikasi", "Rp. " + numberWithCommas(appFee)],
              ["Diskon", "-Rp. " + numberWithCommas(discount)],
              ["Cashback", "-Rp. " + numberWithCommas(cashback)],
              ["MamaPoint", "-Rp. " + numberWithCommas(mamaPointPrice)],
            ])
          }
          total={"Rp. " + numberWithCommas(totalPrice)}
        />
        <div className="dropship">
          <div className="column">
            <div className="row">
              <Switch
                checked={isDropship}
                onChange={(val) => {
                  localStorage.setItem("isDropship", val.toString());
                  setIsDropship(val);
                }}
              />
              <p>Kirim sebagai dropshipper</p>
            </div>
            {isDropship ? (
              <div className="inputs">
                <TextInput
                  value={dropShipName}
                  label="Nama Lengkap"
                  placeholder="Masukkan nama lengkap"
                  onChange={(event) => {
                    localStorage.setItem("dropShipName", event.target.value);
                    setDropShipName(event.target.value);
                  }}
                />
                <TextInput
                  value={dropShipPhone}
                  label="Nomor Whatsapp"
                  placeholder="Masukkan nomor whatsapp"
                  onChange={(event) => {
                    localStorage.setItem("dropShipPhone", event.target.value);
                    setDropShipPhone(event.target.value);
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <CartSummary
        onActionTap={() =>
          Object.keys(selectedShipping).length != 0 && handlePayment()
        }
        showVoucher={false}
        actionText="Pembayaran"
        price={totalPrice}
      />
      <BottomSheet
        open={addressDialog}
        onClose={() => setAddressDialog(false)}
        title="Alamat Pengiriman"
      >
        <form className="dialog-content">
          {addresses.map((address, i) => (
            <div
              className="address-card"
              onClick={() => handleAddress(address)}
              key={i}
            >
              <Radio checked={selectedAddress == address} />
              <div className="col">
                <h6>{address.label}</h6>
                <p>{address.full_address}</p>
              </div>
            </div>
          ))}
          {/* <div className="address-card" onClick={() => setSelected("office")}>
            <Radio checked={selected == "office"} />
            <div className="col">
              <h6>Office</h6>
              <p>Jl. HM Ardans ( Ring Road Sempaja ) Gg..</p>
            </div>
          </div> */}
        </form>
        <div className="row">
          <div
            className="primary-button expanded"
            onClick={() => setAddressDialog(false)}
          >
            Simpan
          </div>
          <div className="width-10"></div>
          <div
            className="secondary-button expanded"
            onClick={() => navigate(routes.addAddress)}
          >
            Tambah Alamat
          </div>
        </div>
      </BottomSheet>
      <ShippingOptions
        onSelected={(courier) => setSelectedShipping(courier)}
        open={isShippingVisible}
        onSubmitButton={handlerCourier}
        onClose={showShipping}
      />
    </div>
  );
}

export default CheckoutPage;
