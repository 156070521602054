import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Api from '../../../../../endpoint/api';

export function useCategories() {
    const getCategoriesFn = async () => {
        const response = await axios.get(`${Api.wp_url}/wp-json/wp/v2/categories`);
        localStorage.setItem('categories', JSON.stringify(response.data));
        return response.data;
    };

    return useQuery({
        queryKey: ['useCategories'],
        queryFn: getCategoriesFn,
    });
}
