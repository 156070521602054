import { useEffect, useRef, useState } from "react";
import { useCategories } from "./queries/use-categories";
import Navbar from "../../components/navbar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ArticleList from "./components/article-list";
import Footer from "../../components/footer";

export default function ArticleHome() {
  const categories = useCategories();
  const [currentTab, setCurrentTab] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
      document.body.classList.add("modal-open");
    }, 2000);
  }, []);

  const handleSelectTab = (index, last) => {
    if (index === last) {
      return;
    }

    setCurrentTab(index);
    setKeyword("");
  };

  return (
    <div className="wrapper-pages">
      <Navbar />

      <div className="article-home-page">
        {/* <div className="hero">
          <img src="images/article-hero.png" alt="" />
        </div> */}

        <div
          className={`modal fade modal-share-article ${
            showModal ? "show" : ""
          }`}
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={
            showModal
              ? { display: "block", backgroundColor: "rgba(0,0,0,.5" }
              : {}
          }
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <img src="/images/article-modal.png" alt="" />

                <svg
                  data-dismiss="modal"
                  onClick={() => {
                    document.body.classList.remove("modal-open");
                    setShowModal(false);
                  }}
                  className="close-icon"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.16167 3.75537C3.94842 3.75537 3.72542 3.82704 3.56267 3.98954C3.23725 4.31538 3.23725 4.86203 3.56267 5.18787L8.79708 10.422L3.56267 15.6562C3.23725 15.982 3.23725 16.5287 3.56267 16.8545C3.88817 17.1795 4.43517 17.1795 4.76067 16.8545L9.995 11.6204L15.2293 16.8545C15.5548 17.1795 16.1018 17.1795 16.4273 16.8545C16.7527 16.5287 16.7527 15.982 16.4273 15.6562L11.1929 10.422L16.4273 5.18787C16.7527 4.86203 16.7527 4.31538 16.4273 3.98954C16.2646 3.82704 16.0415 3.75537 15.8283 3.75537C15.6151 3.75537 15.3922 3.82704 15.2293 3.98954L9.995 9.2237L4.76067 3.98954C4.59792 3.82704 4.37492 3.75537 4.16167 3.75537Z"
                    fill="white"
                  />
                </svg>

                <div className="text">
                  <h1>Bagikan Cerita MengASIhi Mama</h1>
                  <p>
                    Yuk berbagi cerita MengASIhi Mama disini, cerita Mama bisa
                    sangat membantu untuk menginspirasi Mama lainnya
                  </p>
                  <button className="button-custom">Tulis Cerita Mama</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper">
          <div className="container">
            <div className="top">
              {/* <button data-toggle="modal" data-target="#exampleModal">
                buka modal
              </button> */}
              <h1>
                Baca artikel menarik berisi update, tips dan berbagai informasi
                seputar menyusui dan lain-lain!
              </h1>
              <h2>
                <span>Disclaimer:</span> Blog ini ditulis sesuai perkembangan
                ilmu dan fakta terbaru tanpa bermaksud menyerang pihak siapapun
                termasuk orang tua yang memilih untuk tidak menyusui. MamaBear
                mendukung seluruh keputusan menyusui orang tua, baik dengan ASI
                atau susu formula, sebagai bagian dari preferensi pribadi
                masing-masing keluarga.
              </h2>
            </div>

            <div className="search-bar">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  // Add your logic here to handle form submission
                  setKeyword(e.target.elements.keyword.value);
                }}
              >
                <input type="text" name="keyword" placeholder="Cari artikel" />
                <button type="submit">
                  <img className="d-md-none" src="/svg/search-svg.svg" alt="" />
                  <img
                    className="d-none d-md-block"
                    src="/svg/search-svg-desktop.svg"
                    alt=""
                  />
                </button>
              </form>
            </div>

            {categories.isSuccess && (
              <Tabs onSelect={handleSelectTab}>
                <TabList className={"category"}>
                  {categories.data.map((category) => (
                    <Tab key={category.id} className={"item"}>
                      {category.name}
                    </Tab>
                  ))}
                </TabList>

                {categories.data.map((category) => (
                  <TabPanel value={currentTab} key={`tabPanel${category.id}`}>
                    <ArticleList
                      key={category.id}
                      keyword={keyword}
                      category={category}
                    />
                  </TabPanel>
                ))}
              </Tabs>
            )}

          </div>
        </div>
            <div className="afiliator">
              <div className="content">
                <div className="join-reseller join-afiliator">
                  <div className="container">
                    <div className="join-reseller-inner">
                      <div className="right">
                        <div className="text">
                          <h4>Ayoo!!</h4>
                          <h1 className="h1-xl">
                          Bagikan Cerita MengASIhi Mama
                          </h1>
                          <p className="p-md">
                          Bagikan cerita menarik mama untuk dapat menginspirasi seluruh mama di Indonesia!
                          </p>
                        </div>

                        <button
                          onClick={() =>
                            window.open("https://wa.me/628888695757", "_blank")
                          }
                        >
                          Tulis Cerita
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6.91438 16.5315C6.65562 16.8549 6.70806 17.3269 7.03151 17.5856C7.35495 17.8444 7.82692 17.7919 8.08568 17.4685L12.0857 12.4685C12.3048 12.1946 12.3048 11.8054 12.0857 11.5315L8.08568 6.53145C7.82692 6.208 7.35495 6.15556 7.03151 6.41432C6.70806 6.67308 6.65562 7.14505 6.91438 7.46849L10.5396 12L6.91438 16.5315ZM12.9144 16.5315C12.6556 16.8549 12.7081 17.3269 13.0315 17.5856C13.355 17.8444 13.8269 17.7919 14.0857 17.4685L18.0857 12.4685C18.3048 12.1946 18.3048 11.8054 18.0857 11.5315L14.0857 6.53145C13.8269 6.208 13.355 6.15556 13.0315 6.41432C12.7081 6.67308 12.6556 7.14505 12.9144 7.46849L16.5396 12L12.9144 16.5315Z"
                              fill="#DE8D8D"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="left">
                        <img src="images/article-bottom.png" alt="" />
                        <img
                          className="desktop"
                          src="images/article-bottom.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>

      <Footer />
    </div>
  );
}
