import { useQuery } from '@tanstack/react-query';
import { apiClient } from '../../../../../api/api-client';

const getCareerDetailFn = async (id) => {
    const response = await apiClient.get(`/v1/careers/${id}`);
    return response.data.data;
};

export function useCareerDetail(id) {
    return useQuery({
        queryKey: ['useCareerDetail', id],
        queryFn: () => getCareerDetailFn(id),
    });
}
