import { shallow } from 'zustand/shallow'
import settingStore from '../store/setting_store'

const settingFacade = () => {
    const { setting, fetchSetting } = settingStore(
        (state) => ({
            setting: state.setting,
            fetchSetting: state.fetchSetting,
        }),
        shallow
    )

    return { setting, fetchSetting }
}

export default settingFacade