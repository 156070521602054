import logo from "../../../assets/images/logo.png";
import Agreement from "../components/agreement";
import TextInput from "../../shared/components/text_input";
import { useEffect, useState } from "react";
import BackButton from "../components/back_button";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../routes";
import authFacade from "../facades/auth_facade";

function RegisterFormScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setactiveTab] = useState(0);
  const [disabledInput, setDisabledInput] = useState("");
  const { profileForm, profileCompletion, setProfileFormField, logout } =
    authFacade();
  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    setProfileFormField(name as keyof typeof profileForm, value);
  };
  const data = location.state ? location.state : null;

  useEffect(function () {
    if (data == null) {
      navigate(routes.register);
    } else {
      if (data["email"] != null) {
        setProfileFormField("email", data["email"]);
        setDisabledInput("email");
      }
      if (data["phone"] != null) {
        setProfileFormField("phone", data["phone"]);
        setDisabledInput("phone");
      }
    }
  }, []);

  const handleBackButton = () => {
    navigate(routes.register);
  };

  return (
    <div id="auth-root">
      <div className="height-50"></div>
      <div className="height-20"></div>
      <div className="back-button" onClick={handleBackButton}>
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.33333 7.1665L5 10.4998M5 10.4998L8.33333 13.8332M5 10.4998L15 10.4998"
            stroke="white"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Kembali
      </div>
      <div className="height-18"></div>
      <div className="header gapless">
        <h1>Daftar Akun Baru</h1>
        <p>Lengkapi data dibawah ini</p>
      </div>
      <div className="height-45"></div>
      <TextInput
        isObsecured={false}
        label={"Nama Lengkap"}
        placeholder={"Masukkan nama lengkap"}
        name={"name"}
        value={profileForm.name}
        onChange={handleInputChange}
      />
      <div className="height-25"></div>
      <TextInput
        isObsecured={false}
        label={"Email"}
        placeholder={"Masukkan email"}
        name={"email"}
        disabled={disabledInput == "email"}
        value={profileForm.email}
        onChange={handleInputChange}
      />
      <div className="height-25"></div>
      <TextInput
        prefix={
          <select name="" id="">
            <option value="+62">+62</option>
          </select>
        }
        isObsecured={false}
        label={"Nomor Telephone"}
        placeholder={"Masukkan nomor telephone"}
        name={"phone"}
        disabled={disabledInput == "phone"}
        value={profileForm.phone}
        onChange={handleInputChange}
      />
      <div className="height-25"></div>
      <TextInput
        isObsecured={true}
        label={"Kata Sandi"}
        placeholder={"Masukkan kata sandi"}
        // text="Kata sandi terlalu lemah"
        name={"password"}
        value={profileForm.password}
        onChange={handleInputChange}
      />
      <div className="height-25"></div>
      <TextInput
        isObsecured={true}
        label={"Konfirmasi Kata Sandi"}
        placeholder={"Masukkan konfirmasi kata sandi"}
        // text="Kata sandi terlalu lemah"
        name={"confirmationPassword"}
        value={profileForm.confirmationPassword}
        onChange={handleInputChange}
      />
      <div className="height-40"></div>
      <Agreement />
      <div className="height-20"></div>
      <div
        className="primary-button"
        onClick={() =>
          profileCompletion(profileForm).then((success) => {
            if (success) {
              navigate(routes.home);
            }
          })
        }
      >
        Daftar Akun
      </div>
    </div>
  );
}
export default RegisterFormScreen;
