import { useEffect, useState } from "react";

interface SwitchProps {
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

function Switch(props: SwitchProps) {
    const [checked, setChecked] = useState(false);

    useEffect(
        () => {
            setChecked(props.checked ?? false)
        }, [props.checked]
    )

    return (
        <div className={'switch' + (checked ? ' checked' : '')} onClick={() => props?.onChange && props.onChange(!checked)}></div>
    )
}


export default Switch