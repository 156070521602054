export interface ProductPagination {
  current_page?: number;
  data?: Product[];
  first_page_url?: string;
  from?: number;
  last_page?: number;
  last_page_url?: string;
  links?: Link[];
  next_page_url?: any;
  path?: string;
  per_page?: number;
  prev_page_url?: any;
  to?: number;
  total?: number;
}
export interface Product {
  id?: number;
  category_id?: number;
  code?: string;
  name?: string;
  price?: number;
  description?: string;
  brand?: string;
  bpom_number?: string;
  video_url?: string;
  weight?: number;
  height?: number;
  stock?: string;
  is_archived?: number;
  creation_date?: string;
  expired_date?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: any;
  width?: number;
  length?: number;
  rating?: string;
  reviews_count?: number;
  price_text?: string;
  video_url_text?: string;
  user_liked_product?: UserLikedProduct;
  image_url_text?: string;
  product_covers?: ProductCover[];
}

export interface UserLikedProduct {
  id?: number;
  user_id?: number;
  product_id?: number;
  created_at?: string;
  updated_at?: string;
}

export interface ProductCover {
  id?: number;
  product_id?: number;
  file_type?: string;
  file_url?: string;
  created_at?: string;
  updated_at?: string;
  file_url_text?: string;
}

interface ProductModel {
  title?: string;
  images?: string[];
  description?: string;
  rating?: number;
  reviewCount?: number;
  category?: string;
  price?: number;
  discount?: number;
  is_liked: boolean;
}

export const ProductFilter = {
  bestRating: {
    text: "Ulasan Terbaik",
    value: "best_rating",
  },
  lowestPrice: {
    text: "Harga Terendah",
    value: "lowest_price",
  },
  hingestPrice: {
    text: "Harga Tertinggi",
    value: "highest_price",
  },
};

export interface Link {
  url?: string;
  label?: string;
  active?: boolean;
}

export default ProductModel;
