import { shallow } from "zustand/shallow";
import cartStore from "../store/cart_store";

const cartFacade = () => {
  const { carts, fetchCart, storeCart, updateCart, deleteCart } = cartStore(
    (state) => ({
      carts: state.carts,
      fetchCart: state.fetchCart,
      storeCart: state.storeCart,
      updateCart: state.updateCart,
      deleteCart: state.deleteCart,
    }),
    shallow
  );

  return { carts, fetchCart, storeCart, updateCart, deleteCart };
};

export default cartFacade;
