import { create } from 'zustand'
import { createJSONStorage, persist } from "zustand/middleware"
import Network from '../../../utils/network_utils'
import Api from '../../../endpoint/api'
import { Member, MembershipModel, MembershipPagination, SavedTransactionAmount, Voucher } from '../models/membership_model'
import Toast from '../../../utils/toast'


type MembershipStore = {
    vouchers: Map<string, MembershipModel[]>;
    allVouchers: Map<string, MembershipModel[]>;
    memberships: Member[];
    savedTransactions: SavedTransactionAmount[];
    newVoucher: number;
    availableVoucher: number;
    fetchSavedTransactions: () => Promise<void>;
    fetchMemberships: () => Promise<void>;
    fetchVoucherAmount: () => Promise<void>;
    fetchVouchers: () => Promise<void>;
    fetchVoucher: (type: string) => void;
    storeVoucher: (user_voucher_id: number) => Promise<boolean>;
}

const membershipStore = create<MembershipStore>()(
    persist((set, get) => ({
        vouchers: new Map([
            ["free_delivery", []],
            ["cashback", []],
            ["discount", []],
        ]),
        allVouchers: new Map(),
        savedTransactions: [],
        memberships: [],
        newVoucher: 0,
        availableVoucher: 0,
        fetchSavedTransactions: async () => {
            const res = await Network.get(Api.savedTransactionsAmount, {});
            if (res?.ok ?? false) {
                const body = await res!.json()
                const data = body['data']
                set({ savedTransactions: data })
            }
        },
        fetchVoucherAmount: async () => {
            const res = await Network.get(Api.voucherAmount, {});
            if (res?.ok ?? false) {
                const body = await res!.json()
                const data = body['data']
                set({ newVoucher: data['new_vouchers_count'] ?? 0, availableVoucher: data['available_vouchers_count'] ?? 0 })
            }
        },
        fetchMemberships: async () => {
            const res = await Network.get(Api.dmMembership, {});
            if (res?.ok ?? false) {
                const body = await res!.json()
                const data = body['data']
                set({ memberships: data })
            }
        },
        fetchVouchers: async () => {
            const [resFreeDelivery, resCashback, resDiscount] = await Promise.all([
                Network.get(Api.membershipVoucher, { queryParameters: { "voucher_category": "free_delivery" } }),
                Network.get(Api.membershipVoucher, { queryParameters: { "voucher_category": "cashback" } }),
                Network.get(Api.membershipVoucher, { queryParameters: { "voucher_category": "discount" } })]);

            const result = new Map<string, Voucher[]>();
            const responses = {
                'free_delivery': resFreeDelivery,
                'cashback': resCashback,
                'discount': resDiscount,
            }
            for (let i = 0; i < Object.values(responses).length; i++) {
                const [key, res] = Object.entries(responses)[i];
                if (res?.ok ?? false) {
                    const body = await res!.json()
                    const data = body as MembershipPagination
                    result.set(key, data.data ?? [])
                }
            }
            set({ vouchers: result })
        },
        fetchVoucher: async (type) => {
            const res = await Network.get(Api.membershipVoucher, { queryParameters: { "voucher_category": type } });
            if (res?.ok ?? false) {
                const body = await res!.json()
                const data = body['data'] as MembershipPagination
                set((state) => ({ allVouchers: { ...state.allVouchers, [type]: data.data } }))
            }
        },
        storeVoucher: async (user_voucher_id) => {
            const res = await Network.post(Api.vouchers, { data: { "user_voucher_id": user_voucher_id } });
            const body = await res!.json()
            if (!res.ok) {
                Toast.show(body['message'] ?? "error", {})
            }
            if (res?.ok ?? false) {
                const data = body['data']
                return true
            }
            return false
        }
    }),
        {
            name: "membership-storage",
            storage: createJSONStorage(() => sessionStorage)
        }
    ),
)

export default membershipStore