import { shallow } from "zustand/shallow";
import posterStore from "../store/poster_store";

const posterFacade = () => {
  const { posters, fetchPoster } = posterStore(
    (state) => ({
      posters: state.posters,
      fetchPoster: state.fetchPoster,
    }),
    shallow
  );

  return { posters, fetchPoster };
};

export default posterFacade;
