export default function Profile(props) {
    return (
        <div className="our-team-item">
            <img src={props.img} alt="" />
            <div className="text">
                <h2>
                    {props.name}
                </h2>
                <p>{props.job}</p>
            </div>
        </div>
    )
}
