import { create } from "zustand";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";
import { error } from "console";
import Toast from "../../../utils/toast";
import { createJSONStorage, persist } from "zustand/middleware";

interface LoginForm {
  phoneCode?: string;
  phone?: string;
  email?: string;
  password?: string;
}

interface RegisterForm {
  phoneCode?: string;
  phone?: string;
  email?: string;
}

interface ProfileForm {
  phoneCode?: string;
  phone?: string;
  email?: string;
  name?: string;
  password?: string;
  confirmationPassword?: string;
  isMale?: boolean;
}

interface StatusLogin {
  status?: boolean;
  isVerifed?: boolean;
}

interface StatusRegister {
  status?: boolean;
  isOtp?: boolean;
}

type AuthStore = {
  loginForm: LoginForm;
  registerForm: RegisterForm;
  profileForm: ProfileForm;
  isAuthenticated: boolean;
  googleAuth: (token: string) => Promise<boolean>;
  login: (form: LoginForm, type: string) => Promise<StatusLogin>;
  register: (form: RegisterForm, type: string) => Promise<StatusRegister>;
  verifyOtp: (code: string) => Promise<boolean>;
  resendOtp: (form: RegisterForm, type: string) => Promise<boolean>;
  updateFcmToken: (token: string) => Promise<boolean>;
  profileCompletion: (form: ProfileForm) => Promise<boolean>;
  logout: () => Promise<boolean>;
  setField: (field: keyof LoginForm, value: string) => void;
  setRegisterField: (field: keyof RegisterForm, value: string) => void;
  setProfileFormField: (field: keyof ProfileForm, value: string) => void;
  loading: boolean;
  email: string;
  phone: string;
  phone_country_code: string;
  error: string;
};

const authStore = create<AuthStore>()(
  persist(
    (set, get) => ({
      loginForm: {
        phoneCode: "",
        phone: "",
        email: "",
        password: "",
      },
      registerForm: {
        phoneCode: "",
        phone: "",
        email: "",
      },
      profileForm: {
        phoneCode: "",
        phone: "",
        email: "",
        name: "",
        password: "",
        confirmationPassword: "",
        isMale: false,
      },
      setField: (field: keyof LoginForm, value: string) =>
        set((state) => ({ loginForm: { ...state.loginForm, [field]: value } })),
      isAuthenticated: !!getSessionToken(),
      loading: false,
      error: "",
      email: "",
      phone: "",
      phone_country_code: "",
      googleAuth: async (token) => {
        let success = false;

        const res = await Network.post(Api.googleLogin, {
          data: { access_token: token },
        });

        const body = await res.json();

        if (!res.ok) {
          Toast.show(body["message"] ?? "error", {});
          success = false;
        } else {
          localStorage.setItem("token", body?.data?.access_token);
          set({ isAuthenticated: true });
          success = true;
        }

        return success;
      },
      login: async (form, type) => {
        set((state) => ({ ...state, loading: true }));
        var success = false;
        var isVerifed = false;
        try {
          const res = await Network.post(Api.login, {
            data:
              type == "email"
                ? { email: form.email, password: form.password }
                : {
                    phone_country_code: "+62",
                    phone: form.phone,
                    password: form.password,
                  },
            useToken: false,
          });
          const body = await res.json();
          if (!res.ok) {
            set((state) => ({
              ...state,
              error: body["message"] ?? "error",
            }));
            Toast.show(body["message"] ?? "error", {});
          } else {
            set((state) => ({
              ...state,
              error: "",
              email: body.data?.user?.email ?? "email@mail.com",
              phone: body.data?.user?.phone,
              phone_country_code: body.data?.user?.phone_country_code,
            }));
            if (body?.data?.access_token != null) {
              localStorage.setItem("token", body?.data?.access_token);
              isVerifed = true;
              set({ isAuthenticated: true });
            } else {
              isVerifed = false;
            }
            success = true;
          }
        } catch (error) {
          if (error instanceof Error) {
            set((state) => ({
              ...state,
              error: (error as Error).message,
            }));
            success = false;
          }
        } finally {
          set((state) => ({
            ...state,
            loading: false,
          }));
        }
        return {
          status: success,
          isVerifed: isVerifed,
        };
      },
      setRegisterField: (field: keyof RegisterForm, value: string) =>
        set((state) => ({
          registerForm: { ...state.registerForm, [field]: value },
        })),
      register: async (form, type) => {
        set((state) => ({ ...state, loading: true }));
        var status = false;
        var isOtp = false;
        try {
          const res = await Network.post(Api.register, {
            data:
              type == "email"
                ? { email: form.email }
                : { phone_country_code: "+62", phone: form.phone },
            useToken: false,
          });
          const body = await res.json();
          if (!res.ok) {
            set((state) => ({
              ...state,
              error: body["message"] ?? "error",
            }));
            Toast.show(body["message"] ?? "error", {});
          } else {
            if (body["message"] == "null_password") {
              isOtp = true;
            }
            status = true;
          }
        } catch (error) {
          if (error instanceof Error) {
            set((state) => ({
              ...state,
              error: (error as Error).message,
            }));
          }
        } finally {
          set((state) => ({
            ...state,
            loading: false,
          }));
          return {
            status: status,
            isOtp: isOtp,
          };
        }
      },
      verifyOtp: async (code) => {
        set((state) => ({ ...state, loading: true }));
        var returnValue = false;
        try {
          const res = await Network.post(`${Api.verifyOtp}/${code}`, {});
          const body = await res.json();
          if (!res.ok) {
            set((state) => ({
              ...state,
              error: body["message"] ?? "error",
            }));
            Toast.show(body["message"] ?? "error", {});
            // console.log(body["message"] ?? "error");
          } else {
            // if (body["data"]["access_token"] != undefined) {
            //   localStorage.setItem("token", body["data"]["access_token"]);
            // }
            returnValue = true;
          }
        } catch (error) {
          if (error instanceof Error) {
            set((state) => ({
              ...state,
              error: (error as Error).message,
            }));
          }
        } finally {
          set((state) => ({
            ...state,
            loading: false,
          }));
          // if (returnValue) {
          //   set({ isAuthenticated: true });
          // }
          return returnValue;
        }
      },
      resendOtp: async (form, type) => {
        set((state) => ({ ...state, loading: true }));
        var returnValue = false;
        try {
          const res = await Network.post(Api.resendOtp, {
            data:
              form.email != null
                ? { email: form.email, type: type }
                : { phone_country_code: "+62", phone: form.phone, type: type },
            useToken: false,
          });
          const body = await res.json();
          if (!res.ok) {
            set((state) => ({
              ...state,
              error: body["message"] ?? "error",
            }));
            // console.log(body["message"] ?? "error");
          } else {
            returnValue = true;
          }
        } catch (error) {
          if (error instanceof Error) {
            set((state) => ({
              ...state,
              error: (error as Error).message,
            }));
          }
        } finally {
          set((state) => ({
            ...state,
            loading: false,
          }));
          return returnValue;
        }
      },
      updateFcmToken: async (token) => {
        let status = false;

        const res = await Network.post(Api.fcmToken, {
          data: { fcm_token: token },
          useToken: true,
        });

        if (!res.ok) {
          Toast.show("Terjadi kesalahan", {});
          const body = await res.json();
          status = false;
        } else {
          localStorage.setItem("fcm_token", token);
          status = true;
        }

        return status;
      },
      profileCompletion: async (form) => {
        set((state) => ({ ...state, loading: true }));
        var returnValue = false;
        try {
          const res = await Network.post(Api.updateOnRegister, {
            data: {
              _method: "PUT",
              email: form.email,
              phone: form.phone,
              phone_country_code: "+62",
              name: form.name,
              password: form.password,
              password_confirmation: form.confirmationPassword,
            },
            useToken: false,
          });
          const body = await res.json();
          if (!res.ok) {
            set((state) => ({
              ...state,
              error: body["message"] ?? "error",
            }));
            Toast.show(body["message"] ?? "error", {});
            // console.log(body["message"] ?? "error");
          } else {
            returnValue = true;
            localStorage.setItem("token", body["data"]["access_token"]);
            set({ isAuthenticated: true });
          }
        } catch (error) {
          if (error instanceof Error) {
            set((state) => ({
              ...state,
              error: (error as Error).message,
            }));
          }
        } finally {
          set((state) => ({
            ...state,
            loading: false,
          }));
          return returnValue;
        }
      },
      setProfileFormField: (field: keyof ProfileForm, value: string) =>
        set((state) => ({
          profileForm: { ...state.profileForm, [field]: value },
        })),
      logout: async () => {
        try {
          localStorage.clear();
          localStorage.removeItem("token");
          set({ isAuthenticated: false });
          return true;
        } catch (error) {
          return false;
        }
      },
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

function getSessionToken() {
  return localStorage.getItem("token");
}

export default authStore;
