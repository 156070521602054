class Api {
  static _baseUrl = "https://backoffice-demo.mamabear.co.id";
  // static _baseUrl = "https://app.mamabear.co.id";
  static wp_url = "https://ordoapps.com/blog";

  static _v1 = `${this._baseUrl}/api/v1/`;

  // Auth
  static login = `${this._v1}auth`;
  static register = `${this._v1}user`;
  static verifyOtp = `${this._v1}verify-otp`;
  static resendOtp = `${this._v1}resend-otp`;

  static googleLogin = `${this._v1}google`;

  // User
  static getUser = `${this._v1}user`;
  static address = `${this._v1}user-addresses`;
  static userPoints = `${this._v1}user-points`;
  static savedTransactionsAmount = `${this._v1}user/membership/saved-transaction-amounts`;
  static dmSetting = `${this._v1}data-master/settings`;
  static dmMembership = `${this._v1}data-master/membership-levels`;
  static voucherAmount = `${this._v1}user/membership/voucher-numbers`;
  static updateOnRegister = `${this._v1}user/update-on-register`;
  static membershipVoucher = `${this._v1}user/membership/vouchers`;
  static vouchers = `${this._v1}user/vouchers`;

  // Datamaster
  static paymentMethod = `${this._v1}data-master/payment-methods`;
  static couriers = `${this._v1}data-master/couriers`;
  static categories = `${this._v1}data-master/categories`;
  static carousels = `${this._v1}data-master/carousels`;
  static posters = `${this._v1}data-master/posters`;

  // Product
  static product = `${this._v1}products`;
  static productDetail = `${this._v1}products/:id`;
  static productReviews = `${this._v1}products/reviews/:id`;
  static likedProduct = `${this._v1}user/liked-products`;
  static recommendations = `${this._v1}landing-pages/recommendations`;
  static lastSeen = `${this._v1}products/last-seen-user`;

  // Cart
  static cart = `${this._v1}user/carts`;
  static cartUpdate = `${this._v1}user/carts/update`;

  // Order
  static orderFee = `${this._v1}user/order-payments/courier-rates`;
  static order = `${this._v1}user/order-payments`;
  static listOrder = `${this._v1}user/order-payments/filter-payments`;
  static detailOrder = `${this._v1}user/order-payments/:id`;
  static cancelOrder = `${this._v1}user/order-payments/cancel-order`;
  static cancelOrderCancel = `${this._v1}user/order-payments/cancel-order/cancel`;
  static acceptOrder = `${this._v1}user/order-payments/accepted-order`;

  // Review
  static reviews = `${this._v1}user/order-payments/review-order`;
  static sendReview = `${this._v1}user/order-payments/review-send`;

  // Tracking
  static tracking = `${this._v1}user/tracking-order/:id`;

  // Guest Order
  static guestOrderFee = `${this._v1}guest/order-payments/courier-rates`;
  static guestOrder = `${this._v1}guest/order-payments`;

  // Complain
  static complain = `${this._v1}complain`;

  // Notifications
  static fcmToken = `${this._v1}user/update-fcm`;
  static notifications = `${this._v1}user/notifications`;
  static notificationRead = `${this._v1}user/notifications/:id/read`;
}

export default Api;
