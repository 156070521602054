export default function CardProfitReseller(props) {
  return (
    <div className="why-reseller-card">
      <div className="top">
        <div className="left">{props.img}</div>

        <div className="right">
          <h2>{props.title}</h2>
        </div>
      </div>
      <h2 className="desktop">{props.title}</h2>
      <div className="line"></div>
      <div className="text">
        <p>{props.desc}</p>
      </div>
    </div>
  );
}
