import { createJSONStorage, persist } from "zustand/middleware";
import { Category } from "../models/category_model";
import { create } from "zustand";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";
import { ProductPagination } from "../../product/models/product_model";

type CategoryStore = {
  categories: Category[];
  fetchCategories: () => void;
};

const categoryStore = create<CategoryStore>()(
  persist(
    (set, get) => ({
      categories: [],
      products: {},
      fetchCategories: async () => {
        const res = await Network.get(Api.categories, { useToken: false });
        if (res?.ok ?? false) {
          const body = await res!.json();
          const categories = body["data"];

          await Promise.all(
            categories.map(async (category: Category, index: number) => {
              const productRes = await Network.get(Api.product, {
                queryParameters: { category_id: category.id },
              });

              if (productRes?.ok ?? false) {
                const productBody = await productRes!.json();
                const products = productBody["data"];
                categories[index].products = products;
              }
            })
          );

          set({ categories: categories });
        }
      },
    }),
    {
      name: "category-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default categoryStore;
