import { ReactNode, useEffect, useState } from "react";
import icClose from '../../../assets/images/ic_close.svg';

interface BottomSeetProps {
    open?: boolean;
    isScroll?: boolean;
    showAction?: boolean;
    title?: string;
    onClose?: () => void;
    children: ReactNode;
}
function BottomSheet(props: BottomSeetProps) {
    const [isOpen, setIsOpen] = useState(props.open || false);
    const [showContent, setShowContent] = useState(props.open || false);

    useEffect(() => {
        setIsOpen(props.open || false);
        setTimeout(() => {
            setShowContent(props.open || false)
        }, 200);
    }, [props.open]);

    const toggleSheet = () => {
        if (isOpen && props.onClose) {
            setShowContent(false)
            setTimeout(() => {
                if (props.onClose) {
                    props.onClose();
                }
            }, 200);
        }
    };
    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <div onClick={toggleSheet} style={{ display: !isOpen ? "none" : "block" }} className={`bottom-sheet ${showContent ? 'open' : ''}`}>
            <div  className={`bottom-sheet-content ${props.isScroll ? 'no-bottom' : ''}`} onClick={stopPropagation}>
                <div className="sheet-header">
                    <img src={icClose} className="close" onClick={toggleSheet} />
                    <div className="title">
                        {props.title ?? 'test'}
                    </div>
                    <div className="action">
                        {
                            props.showAction ?? false ?
                                <div className="reset">Hapus</div>
                                : null
                        }
                    </div>
                </div>
                {props.children}
            </div>
        </div>
    );
}

export default BottomSheet;