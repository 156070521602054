import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import orderFacade from "../../order/facades/order_facade";
import moment from "moment";
import "moment/locale/id";

interface StatusModel {
  text: string;
  label: string;
}

const statusMap: { [key: string]: StatusModel } = {
  processed: {
    text: "Pesanan di proses",
    label: "Diproses",
  },
  need_process: {
    text: "Pesanan di menunggu diproses",
    label: "Menunggu Diproses",
  },
  sending: {
    text: "Pesanan sedang dalam proses pengiriman",
    label: "Dikirim",
  },
  unpaid: {
    text: "Pesanan menunggu dibayar",
    label: "Menunggu Pembayaran",
  },
  cancel_wait: {
    text: "Menunggu proses pembatalan",
    label: "Pengajuan Pembatalan",
  },
  cancelled: {
    text: "Pesanan dibatalkan oleh MamaBear",
    label: "Dibatalkan",
  },
  finished: {
    text: "Pesanan belum diulas",
    label: "Diterima",
  },
};

function InvoicePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { order } = orderFacade();

  const orderDate = moment(order.data?.created_at ?? new Date())
    .add(7, "hours")
    .format("DD MMMM YYYY, HH:mm");

  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  return (
    <div className="page invoice-page">
      <div className="header">
        <svg
          className="back"
          onClick={() => navigate(-1)}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 8.5L6 12.5M6 12.5L10 16.5M6 12.5L18 12.5"
            stroke="#191919"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>Invoice</p>
      </div>
      <div className="page-content">
        <div className="invoice-card">
          <div className="row">
            <div className="title">Detail Pesanan</div>
            <div className="text">{order.data?.code}</div>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="label">Tanggal Pembelian</div>
            <div className="text">{orderDate}</div>
          </div>
          <div className="row">
            <div className="label">Status Pesanan</div>
            <div className="text">
              {statusMap[order.data?.status ?? "processed"].label}
            </div>
          </div>
          <div className="row">
            <div className="label">Metode Pembayaran</div>
            <div className="text">
              {order.data?.order_payment?.payment_gateway_detail?.metadata
                ?.bank ?? "BCA"}{" "}
              - Virtual Akun
            </div>
          </div>
        </div>

        <div className="invoice-card">
          <div className="row">
            <div className="title">Detail Pengiriman</div>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="label">Nama Pembeli</div>
            <div className="text">
              {order.data?.destination_address_json?.receiver_name}
            </div>
          </div>
          <div className="row">
            <div className="label">No. Kontak</div>
            <div className="text">
              {order.data?.destination_address_json?.phone}
            </div>
          </div>
          <div className="row">
            <div className="label">Alamat</div>
            <div className="text">
              {order.data?.destination_address_json?.label}
            </div>
          </div>
          <div className="row">
            <div className="expanded text">
              {order.data?.destination_address_json?.full_address}
            </div>
          </div>
        </div>

        <div className="invoice-card">
          <div className="row">
            <div className="title">Detail Produk</div>
            <div className="text">
              {order.data?.order_products?.length ?? 0} Produk
            </div>
          </div>
          <div className="divider"></div>
          {order.data?.order_products?.map((product) => (
            <div className="column">
              <div className="label">{product.product_json.name}</div>
              <div className="row">
                <div className="text"></div>
                <div className="text">
                  {product.qty} x Rp{" "}
                  {numberWithCommas(product.product_json.price)}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="invoice-card">
          <div className="row">
            <div className="title">Ringkasan Pembayaran</div>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="label">Total Harga</div>
            <div className="text">
              Rp{" "}
              {numberWithCommas(order.data?.order_payment?.final_amount ?? 0)}
            </div>
          </div>
          <div className="row">
            <div className="label">Ongkos Kirim (600 gr)</div>
            <div className="text">
              Rp{" "}
              {numberWithCommas(order.data?.order_payment?.delivery_fee ?? 0)}
            </div>
          </div>
          <div className="row">
            <div className="label">Biaya Aplikasi</div>
            <div className="text">
              Rp{" "}
              {numberWithCommas(
                order.data?.order_payment?.application_fee ?? 0
              )}
            </div>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="label">Diskon</div>
            <div className="text">
              -Rp{" "}
              {numberWithCommas(
                order.data?.order_payment?.discount_amount ?? 0
              )}
            </div>
          </div>
          <div className="row">
            <div className="label">Cashback</div>
            <div className="text">
              -Rp{" "}
              {numberWithCommas(
                order.data?.order_payment?.cashback_amount ?? 0
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoicePage;
