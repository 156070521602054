import { useEffect, useState } from "react";
import dummyProduct from "../../../assets/images/product-1.png";
import { Cart, Product } from "../models/cart_model";
import cartFacade from "../facades/cart_facade";
import authFacade from "../../authentication/facades/auth_facade";
import Toast from "../../../utils/toast";
import productFacade from "../../product/facade/product_facade";

interface CartItemProps {
  totalItems?: number;
  selected?: boolean;
  onSelectedChange?: (val: boolean) => void;
  cart?: Cart;
  updateTotalProps?: (cart?: Cart) => void;
  needWishlist?: boolean;
}
function CartItem(props: CartItemProps) {
  const { isAuthenticated } = authFacade();
  const { updateCart } = cartFacade();
  const { likeProduct } = productFacade();
  const [count, setCount] = useState(props.cart?.qty ?? 0);

  const [isWishlistActive, setIsWishlistActive] = useState(
    !props.needWishlist && !props.cart?.product?.user_liked_product
  );

  const handleLike = async (productId: number) => {
    likeProduct(productId).then((res) => {
      if (res) {
        setIsWishlistActive(false);
      }
    });
  };

  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  const handleMinus = () => {
    if (count > 0) {
      setCount(count - 1);
      if (isAuthenticated) {
        updateCart({
          product_id: props.cart?.product?.id,
          qty: count - 1,
          _method: "PUT",
        }).then((res) => {
          if (res) {
            setCount(count - 1);
            let sendCart = props.cart;

            if (sendCart) {
              sendCart.qty = count - 1;
            }

            props.updateTotalProps?.(sendCart ?? {});
          } else {
            Toast.show("Gagal Memperbarui Keranjang", {});
            setCount(count);
          }
        });
      } else {
        const unauthCarts = JSON.parse(
          localStorage.getItem("unauthCarts") ?? "[]"
        ) as Cart[];
        const index = unauthCarts.findIndex(
          (cart) => cart.product_id == props.cart?.product_id
        );
        let sendCart = props.cart;

        if (index != -1) {
          unauthCarts[index].qty = count - 1;
          localStorage.setItem("unauthCarts", JSON.stringify(unauthCarts));

          if (sendCart) {
            sendCart.qty = count - 1;
          }
        }
        props.updateTotalProps?.(sendCart ?? {});
      }
    }
  };

  const handlePlus = () => {
    if (props.cart?.product?.stock) {
      if (count < props.cart?.product?.stock) {
        setCount(count + 1);
        if (isAuthenticated) {
          updateCart({
            product_id: props.cart?.product?.id,
            qty: count + 1,
            _method: "PUT",
          }).then((res) => {
            if (res) {
              setCount(count + 1);

              let sendCart = props.cart;

              if (sendCart) {
                sendCart.qty = count + 1;
              }

              props.updateTotalProps?.(sendCart ?? {});
            } else {
              Toast.show("Gagal Memperbarui Keranjang", {});
              setCount(count);
            }
          });
        } else {
          const unauthCarts = JSON.parse(
            localStorage.getItem("unauthCarts") ?? "[]"
          ) as Cart[];
          const index = unauthCarts.findIndex(
            (cart) => cart.product_id == props.cart?.product_id
          );
          let sendCart = props.cart;
          if (index != -1) {
            unauthCarts[index].qty = count + 1;
            localStorage.setItem("unauthCarts", JSON.stringify(unauthCarts));

            if (sendCart) {
              sendCart.qty = count + 1;
            }
          }
          props.updateTotalProps?.(sendCart ?? {});
        }
      }
    }
  };

  return (
    <div className="cart-item">
      <div className="row">
        {props.totalItems != null ? null : (
          <div
            className={`check-box${props.selected ?? false ? " active" : ""}`}
            onClick={() =>
              props?.onSelectedChange?.(!(props.selected ?? false))
            }
          ></div>
        )}
        <figure>
          <img src={props.cart?.product?.image_url_text ?? dummyProduct} />
        </figure>
        <div className="col">
          <h6>{props.cart?.product?.name}</h6>
          <div className="row">
            <div className="price">
              Rp
              <span>{numberWithCommas(props.cart?.product?.price ?? 0)}</span>
            </div>
            {props.totalItems != null ? (
              <span>x {props.totalItems} Barang</span>
            ) : (
              <div className="stock">
                <button onClick={handleMinus}></button>
                <div className="count">{count}</div>
                <button onClick={handlePlus} className="add"></button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isWishlistActive ? (
        <>
          <div
            className="action-btn"
            onClick={() => handleLike(props.cart?.product?.id ?? 0)}
          >
            Tambahkan Ke Favorit
          </div>
        </>
      ) : null}
    </div>
  );
}

export default CartItem;
