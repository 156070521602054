import { ReactNode, useEffect, useState } from "react";
import BottomSheet from "../../../../shared/components/bottom_sheet";
import penIcon from "../../../../../assets/images/ic_pen.svg"
import trashIcon from "../../../../../assets/images/ic_trash.svg"
import { useNavigate } from "react-router-dom";
import routes from "../../../../../routes";

interface AddressMoreOptionProp {
    open?: boolean;
    onEdit?: () => void;
    onClose?: () => void;
    onDelete?: () => void;
}

function AddressMoreOptionDialog(props: AddressMoreOptionProp) {
    const navigate = useNavigate()

    return (
        <BottomSheet open={props.open} onClose={props.onClose} title="Pilihan Lainnya">
            <div className="more-options">
                <div className="option" onClick={props.onEdit}>
                    <img src={penIcon} className="icon" />
                    <p>
                        Ubah Alamat
                    </p>
                </div>
                <div className="option" onClick={props.onDelete}>
                    <img src={trashIcon} className="icon" />
                    <p>
                        Hapus Alamat
                    </p>
                </div>
            </div>
        </BottomSheet>
    )
}

export default AddressMoreOptionDialog