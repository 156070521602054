import { ReactNode, useState } from 'react';
import eye from '../../../assets/images/ic_eye.svg';
import eyeDisable from '../../../assets/images/ic_eye-disable.svg';

interface TextInputProps {
    text?: string;
    label?: string;
    placeholder?: string;
    value?: string;
    name?: string;
    type?: string;
    max?: string | number;
    isObsecured?: boolean;
    isMultiline?: boolean;
    isOutlined?: boolean;
    disabled?: boolean;
    isPrimary?: boolean;
    prefix?: ReactNode;
    children?: ReactNode;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
}

function TextInput(
    props: TextInputProps
) {
    const [obsecured, setObsecured] = useState(props.isObsecured ?? false);

    return (
        <div className="text-input">
            {props.label !== undefined ? (
                <label>{props.label}</label>
            ) : null}
            <div className={`input${props.isPrimary ?? true ? '' : ' secondary'}${props.isOutlined ?? false ? ' outlined' : ''}`}>
                {
                    props.prefix !== undefined ?
                        <div className="prefix">
                            {props.prefix}
                        </div> : null
                }
                {
                    props.isMultiline ?? false ?
                        <textarea disabled={props.disabled} name={props.name} onChange={props.onChange} placeholder={props.placeholder} rows={4} value={props.value}>
                            {props.children}
                        </textarea> :
                        props.children == null ?
                            <input disabled={props.disabled} name={props.name} onChange={props.onChange} type={obsecured ? 'password' : props.type ?? 'text'} max={props.max} placeholder={props.placeholder} value={props.value} />
                            :
                            // <select disabled={props.disabled} name={props.name} onChange={props.onChange} placeholder={props.placeholder} value={props.value}>
                            //     {props.children}
                            // </select>
                            <select disabled={props.disabled} name={props.name} onChange={props.onChange} value={props.value}>
                                {props.children}
                            </select>
                }
                {props.isObsecured ?? false ? (
                    <div className="suffix" onClick={function () {
                        setObsecured(!obsecured);
                    }}>
                        {obsecured ?
                            <img src={eye} alt="" /> :
                            <img src={eyeDisable} alt="" />
                        }
                    </div>
                ) : null}
            </div>
            {props.text !== undefined ? (
                <p>{props.text}</p>
            ) : null}
        </div>
    );
}

export default TextInput;