import { useNavigate } from "react-router-dom";
import back from "../../../assets/images/ic_arrow-left.svg";
import VoucherItem from "../components/voucher_item";
import EmptyVoucher from "../components/empty_state";
import ProductModel from "../../product/models/product_model";
import { useEffect, useState } from "react";
import emptyVoucherIcon from "../../../assets/images/empty_voucher.svg";
import VoucherDetail from "./voucher_detail";
import voucherFacade from "../facades/voucher_facade";
import { MembershipModel, Voucher } from "../models/membership_model";

function VoucherPage() {
  const { vouchers, fetchVouchers } = voucherFacade();
  const navigate = useNavigate();

  const [products, setProducts] = useState<ProductModel[]>([]);
  const [isDetailVisible, showDetail] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState<MembershipModel>();

  const [code, setCode] = useState("");

  useEffect(() => {
    fetchVouchers(code);
  }, [code]);

  const handleDetail = (voucher: Voucher) => {
    setSelectedVoucher(voucher);
    showDetail(true);
  };

  return (
    <div className="voucher-page">
      <div className="header">
        <img
          src={back}
          alt=""
          className="back-btn"
          onClick={() => navigate(-1)}
        />
        <div className="title">Voucher</div>
        <input
          type="text"
          placeholder="Cari Kode Voucher"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          // onBlur={handleBlur}
        />
      </div>
      <div className="content">
        {vouchers.total == 0 ? (
          <EmptyVoucher
            icon={emptyVoucherIcon}
            title="Voucher Tidak Berlaku"
            subtitle="Kode voucher tidak berlaku, silahkan<br/>coba kode lainnya."
          ></EmptyVoucher>
        ) : (
          vouchers?.data?.map((vouchers, i) => (
            <VoucherItem
              key={i}
              voucher={vouchers}
              onActionTap={() => handleDetail(vouchers ?? {})}
            ></VoucherItem>
          ))
        )}
      </div>
      <VoucherDetail
        userVoucher={selectedVoucher}
        open={isDetailVisible}
        onClose={showDetail}
      />
    </div>
  );
}

export default VoucherPage;
