interface OrderSummaryProps {
    data: Map<string, string>;
    labelTotal?: string;
    total?: string;
}
function OrderSummary(props: OrderSummaryProps) {
    return (
        <div className="order-summary" style={{ padding: 15 }}>
            <h6>
                Ringkasan Pembayaran
            </h6>
            <div className="card">
                {Array.from(props.data.entries()).map(([key, value]) => (
                    <div className="text" key={key}>
                        <div className="title">
                            {key}
                        </div>
                        <div className="subtitle">
                            {value}
                        </div>
                    </div>
                ))
                }
                {
                    props.total != null ?
                        <>
                            <hr className="dotted" />
                            <div className="text">
                                <div className="title">
                                    {props.labelTotal ?? 'Total'}
                                </div>
                                <div className="subtitle">
                                    {props.total}
                                </div>
                            </div>
                        </> : null
                }
            </div>
        </div>
    )
}

export default OrderSummary