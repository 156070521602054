import { shallow } from 'zustand/shallow'
import membershipStore from '../store/membership_store'

const membershipFacade = () => {
    const { vouchers, allVouchers, memberships, newVoucher, availableVoucher, savedTransactions, fetchSavedTransactions, fetchVouchers, fetchVoucher, fetchMemberships, fetchVoucherAmount, storeVoucher } = membershipStore(
        (state) => ({
            vouchers: state.vouchers,
            newVoucher: state.newVoucher,
            allVouchers: state.allVouchers,
            memberships: state.memberships,
            availableVoucher: state.availableVoucher,
            savedTransactions: state.savedTransactions,
            fetchVoucher: state.fetchVoucher,
            fetchVouchers: state.fetchVouchers,
            fetchMemberships: state.fetchMemberships,
            fetchVoucherAmount: state.fetchVoucherAmount,
            fetchSavedTransactions: state.fetchSavedTransactions,
            storeVoucher: state.storeVoucher
        }),
        shallow
    )

    return { vouchers, allVouchers, memberships, newVoucher, availableVoucher, savedTransactions, fetchSavedTransactions, fetchVouchers, fetchVoucher, fetchMemberships, fetchVoucherAmount, storeVoucher }
}

export default membershipFacade