import { ReactNode, MouseEventHandler } from "react";

interface CartSectionProps {
    title?: string,
    text?: string,
    prefix?: ReactNode;
    suffix?: ReactNode;
    onTap?: MouseEventHandler;
}
function CartSection(props: CartSectionProps) {
    return (
        <div className="cart-section">
            <h6>
                {props.title ?? 'Alamat Pengiriman'}
            </h6>
            <div className="cart-section-content" onClick={props.onTap}>
                {props.prefix}
                <p>
                    {props.text ?? 'Tambah alamat'}
                </p>
                {props.suffix ?? <div className="arrow"></div>}
            </div>
        </div>
    )


}

export default CartSection