import { ReactNode, useEffect, useState } from "react";
import icon from "../../../../../assets/images/empty_address.svg";
import BottomSheet from "../../../../shared/components/bottom_sheet";

interface EditFormProp {
    open?: boolean;
    onClose?: () => void;
    onDelete?: () => void;
}

function DeleteAddressDialog(props: EditFormProp) {
    return (
        <BottomSheet open={props.open} onClose={props.onClose} title="Ubah Data">
            <div className="empty-state">
                <img src={icon} />
                <h5>
                    Hapus Alamat
                </h5>
                <p>
                    Anda yakin ingin menghapus alamat ini?
                </p>
            </div>
            <div className="height-25"></div>
            <div className="row">
                <div className="expanded">
                    <div className="primary-button" onClick={() => { props.onDelete?.(); props.onClose?.() }}>
                        Yakin
                    </div>
                </div>
                <div className="width-10"></div>
                <div className="expanded">
                    <div className="secondary-button" onClick={props.onClose}>
                        Batal
                    </div>
                </div>
            </div>
        </BottomSheet>
    )
}

export default DeleteAddressDialog