import { create } from "zustand";
import { PosterModel } from "../models/poster_model";
import { createJSONStorage, persist } from "zustand/middleware";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";

type PosterStore = {
  posters: PosterModel[];
  fetchPoster: () => void;
};

const posterStore = create<PosterStore>()(
  persist(
    (set, get) => ({
      posters: [],
      fetchPoster: async () => {
        const res = await Network.get(Api.posters, { useToken: false });
        if (res?.ok ?? false) {
          const body = await res!.json();
          const posters = body["data"];
          set({ posters: posters });
        }
      },
    }),
    {
      name: "poster-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default posterStore;
