import { create } from "zustand";
import { CarouselModel } from "../models/carousel_model";
import { createJSONStorage, persist } from "zustand/middleware";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";

type CarouselStore = {
  carousels: CarouselModel[];
  fetchCarousel: () => void;
};

const carouselStore = create<CarouselStore>()(
  persist(
    (set, get) => ({
      carousels: [],
      fetchCarousel: async () => {
        const res = await Network.get(Api.carousels, { useToken: false });
        if (res?.ok ?? false) {
          const body = await res!.json();
          const carousels = body["data"];
          set({ carousels: carousels });
        }
      },
    }),
    {
      name: "carousel-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default carouselStore;
