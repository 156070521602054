import BottomSheet from "../../shared/components/bottom_sheet";
import voucherBanner from "../../../assets/images/voucher-banner.png";
import { MembershipModel, Voucher } from "../models/membership_model";
import { useNavigate } from "react-router-dom";

interface VoucherDetailSeetProps {
  open?: boolean;
  onClose?: (val: boolean) => void;
  userVoucher?: MembershipModel;
}
function VoucherDetail(props: VoucherDetailSeetProps) {
  const navigate = useNavigate();

  const voucher = props.userVoucher?.voucher;

  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  const handleUseVoucher = () => {
    localStorage.setItem("voucher", JSON.stringify(props.userVoucher));
    props?.onClose?.(false);
    navigate(-1);
  };

  return (
    <BottomSheet
      open={props?.open ?? false}
      onClose={() => props?.onClose?.(false)}
      title="Voucher Detail"
    >
      <div className="voucher-detail">
        <img
          src={voucher?.image_url_text ?? voucherBanner}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.src = voucherBanner;
          }}
          alt=""
          className="banner"
        />
        <div className="voucher-info">
          <div className="column expanded">
            <h5>{voucher?.name}</h5>
            <p>
              Berlaku{" "}
              {voucher?.start_date_text + " - " + voucher?.end_date_text}
            </p>
          </div>
          <div className="value">
            <div className="label">Nominal</div>
            <p>
              Rp {numberWithCommas(voucher?.value ?? 0)}
              <span></span>
            </p>
          </div>
        </div>
        <hr className="dotted" />
        <div className="terms">
          <h6>Ketentuan</h6>
          <ul>
            <li>Gold Member</li>
            <li>Semua Kategori Produk</li>
            <li>Min. Pembelian Rp 150.000</li>
          </ul>
        </div>
      </div>
      <div className="primary-button" onClick={handleUseVoucher}>
        Pakai Voucher
      </div>
    </BottomSheet>
  );
}

export default VoucherDetail;
