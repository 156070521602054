import React, { useState, useEffect } from 'react';

interface CountdownProps {
    onCompleted: () => void
    duration?: number
}

function Countdown(props: CountdownProps) {
    const [totalSeconds, setTotalSeconds] = useState(props.duration ?? 119); // 2 minutes = 120 seconds

    useEffect(() => {
        const timer = setInterval(() => {
            if (totalSeconds === 0) {
                clearInterval(timer);
                props.onCompleted()
            } else {
                setTotalSeconds(totalSeconds - 1);
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [totalSeconds]);

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formatTime = (value: number) => {
        return value < 10 ? `0${value}` : value;
    };

    return (
        <div className="count-down">
            {`${formatTime(minutes)}:${formatTime(seconds)}`}
        </div>
    );
}

export default Countdown;
