import { useState, useRef, useEffect } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "react-tabs/style/react-tabs.css";

export default function OurStory() {
  // const [isPlay, setIsPlay] = useState(false);
  // const [currentTab, setCurrentTab] = useState(0);
  // const handleSelectTab = (index) => {
  //   setCurrentTab(index);
  // };
  // const videoRef = useRef(null);

  // const handlePlayClick = () => {
  //   if (videoRef.current.paused) {
  //     videoRef.current.play();
  //     setIsPlay(true);
  //   } else {
  //     videoRef.current.pause();
  //     setIsPlay(false);
  //   }
  // };

  // function ProfitItem(props) {
  //   return (
  //     <div className="item">
  //       <img src={ `svg/story-profit-svg-${props.imgProfit}.svg` } alt="" />
  //       <div className="text">
  //         <h3>{ props.titleProfit }</h3>
  //         <p>{ props.desc }</p>
  //       </div>
  //     </div>
  //   );
  // }
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOddIndex, setIsOddIndex] = useState(false);
  const contentRef = useRef(null); // Initial active index

  // const handleItemClick = (index) => {
  //   setActiveIndex(index);
  // };
  useEffect(() => {
    // Scroll to the active item when it changes
    if (contentRef.current) {
      contentRef.current.children[activeIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [activeIndex]);
  // const handlePageClick = () => {
  //   const nextIndex = (activeIndex + 1) % contentItems.length;
  //   setActiveIndex(nextIndex);
  //   setIsOddIndex(nextIndex !== 0 && nextIndex % 2 !== 0);

  //   if (targetRef.current) {
  //     targetRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const contentItems = [
    {
      year: "2014",
      title: "Berawal dari Gagal Menyusui Anak Pertama",
      description:
        "Agnes (Founder MamaBear) melakukan personal riset dan menformulasi produk MamaBear Teh Pelancar ASI dari dapur orangtuanya.",
    },
    {
      year: "2016",
      title: "Konsultasi Menyusui Gratis 24 Jam",
      description:
        "MamaBear mendampingi perjalanan menyusui Mama dengan menghadirkan Konsultasi Gratis bersama dengan Konselor Menyusui yang sudah tersertifikasi.",
    },
    {
      year: "2018",
      title: "MamaBear Membantu 1.000.000++ Ibu Menyusui di Indonesia",
      description:
        "Dengan produk yang berkualitas yang sudah tersertifikasi HALAL dan BPOM, serta pendistribusian produk yang merata. Kami bisa membantu lebih dari 1.000.000 Ibu Menyusui di Indonesia.",
    },
    {
      year: "2021",
      title: "Pertama kali mendapatkan Brand Choice",
      description:
        "Dengan 3.000.000 ++ Ibu Menyusui di Indonesia yang terbantu dengan produk MamaBear, di tahun ini kami menerima penghargaan Brand Choice pertama kalinya.",
    },
    {
      year: "2023",
      title: "Berawal dari Gagal Menyusui Anak Pertama",
      description:
        "MamaBear mendapatkan Brand Choice selama 3 tahun berturut-turut tahun 2021-2023. Dan juga Brand Choice Mom n Kids tahun 2022 - 2023. Dengan kepercayaan yang tinggi dari customer, MamaBear sudah membantu 5.000.000++ Ibu Menyusui di Indonesia.",
    },
  ];

  const images = [
    "images/story1.png",
    "images/story2.png",
    "images/story3.png",
    "images/story5.png",
    "images/story6.png",
  ];

  const scrollTimeoutRef = useRef(null);
  const targetRef = useRef(null);
  const [allowScroll, setAllowScroll] = useState(true);

  const handlePageClick = () => {
    const nextIndex = (activeIndex + 1) % contentItems.length;
    setActiveIndex(nextIndex);
    setIsOddIndex(nextIndex !== 0 && nextIndex % 2 !== 0);

    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const handleScroll = () => {
  //   if (scrollTimeoutRef.current) {
  //     clearTimeout(scrollTimeoutRef.current);
  //   }

  //   scrollTimeoutRef.current = setTimeout(() => {
  //     handlePageClick();
  //   }, 100);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);

  //     if (scrollTimeoutRef.current) {
  //       clearTimeout(scrollTimeoutRef.current);
  //     }
  //   };
  // });

  const imageRef = useRef(null);

  const handleScroll = () => {
    document.querySelector(".wrapper-pages").scrollIntoView();
    const imageContainer = imageRef.current;
    const sections = imageContainer.querySelectorAll("section");

    for (let index = 0; index < sections.length; index++) {
      const rect = sections[index].getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
      const contentYear = document.querySelectorAll(".content-year-items");
      const contentYearParent = document.querySelector(".parent-content-year");
      const storyItemWrapperDekstop = document.querySelector(
        ".story-item-wrapper-desktop"
      );

      if (isVisible) {
        contentYear[index].classList.add("active");
        // console.log("sampai ke section", index + 1);

        if (index === 1 || index === 3) {
          contentYearParent.classList.add("even");
          storyItemWrapperDekstop.classList.add("even");
        } else {
          contentYearParent.classList.remove("even");
          storyItemWrapperDekstop.classList.remove("even");
        }

        if (index === 4) {
          contentYear[0].style.display = "none";
          contentYear[1].style.display = "none";
        } else {
          contentYear[0].style.display = "flex";
          contentYear[1].style.display = "flex";
        }
      } else {
        contentYear[index].classList.remove("active");
      }
    }
  };

  useEffect(() => {
    // Menambahkan event listener untuk event scroll di dalam div .images
    const imagesContainer = imageRef.current;
    imagesContainer.addEventListener("scroll", handleScroll);

    // Membersihkan event listener saat komponen di-unmount
    return () => {
      imagesContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="wrapper-pages">
      <Navbar />
      {/* <div className="afiliator">
        <div className="heading">
          <div
            className="play-button-wrapper"
            style={ { display: isPlay ? "none" : "flex" } }
            onClick={ handlePlayClick }
          >
            <svg
              className="play-button"
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="80" height="80" rx="40" fill="#EEAAAA" />
              <path
                d="M60.4096 39.8013C60.4109 40.3418 60.2723 40.8734 60.0073 41.3445C59.7423 41.8156 59.3598 42.2101 58.8972 42.4897L30.2246 60.0291C29.7412 60.3251 29.1875 60.4867 28.6207 60.4972C28.054 60.5077 27.4947 60.3667 27.0007 60.0888C26.5114 59.8153 26.1037 59.4163 25.8197 58.9329C25.5357 58.4496 25.3856 57.8993 25.3848 57.3387V22.2638C25.3856 21.7032 25.5357 21.1529 25.8197 20.6696C26.1037 20.1862 26.5114 19.7873 27.0007 19.5137C27.4947 19.2358 28.054 19.0948 28.6207 19.1053C29.1875 19.1158 29.7412 19.2774 30.2246 19.5734L58.8972 37.1128C59.3598 37.3924 59.7423 37.7869 60.0073 38.258C60.2723 38.7291 60.4109 39.2607 60.4096 39.8013Z"
                fill="white"
              />
            </svg>
          </div>
          <video
            ref={ videoRef }
            controls={ !isPlay ? false : true }
            src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4"
          ></video>
        </div>

        <div className="content">
          <section className="why-section afiliator our-story">
            <div className="container">
              <h1>
                <span>Perjalanan</span> Mamabear Indonesia
              </h1>

              <div className="paragraf">
                <p>
                  Sekilas, cerita Mamabear mungkin hanya tentang keinginan
                  seorang mama untuk menyusui anaknya. Tetapi, setiap langkah
                  Mamabear juga bercerita tentang perjuangan sosok mama
                  menghadapi tantangan membesarkan anak, baik secara fisik
                  maupun mental. Agnes Susanti Widjaja adalah mama dari 3 anak
                  sekaligus founder Mamabear Indonesia. Sama seperti semua mama
                  yang baru mulai menyusui, ekspektasi Agnes adalah ASI lancar
                  setelah melahirkan.
                </p>

                <br />

                <p>
                  Tapi realitanya, ASI tidak lancar mengakibatkan problem berat
                  badan bayi yang tidak kunjung naik. Bahkan, Agnes juga
                  merasakan baby blues[outlink ke jurnal] yang mengarah ke
                  depresi paska melahirkan. Akhirnya, impian ASI eksklusif harus
                  berakhir hanya setelah 40 hari setelah melahirkan karena sulit
                  ASI.
                </p>

                <br />

                <p>
                  Walaupun berat, ini jadi motivasi bagi Agnes untuk mencari
                  solusi ASI yang efektif
                </p>
              </div>

              <button>
                Lebih Lanjut
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.96855 6.97688C7.6451 6.71812 7.17313 6.77056 6.91438 7.09401C6.65562 7.41745 6.70806 7.88942 7.03151 8.14818L12.0315 12.1482C12.3054 12.3673 12.6946 12.3673 12.9685 12.1482L17.9685 8.14818C18.292 7.88942 18.3444 7.41745 18.0857 7.09401C17.8269 6.77056 17.355 6.71812 17.0315 6.97688L12.5 10.6021L7.96855 6.97688ZM7.96855 12.9769C7.6451 12.7181 7.17313 12.7706 6.91438 13.094C6.65562 13.4175 6.70806 13.8894 7.03151 14.1482L12.0315 18.1482C12.3054 18.3673 12.6946 18.3673 12.9685 18.1482L17.9685 14.1482C18.292 13.8894 18.3444 13.4175 18.0857 13.094C17.8269 12.7706 17.355 12.7181 17.0315 12.9769L12.5 16.6021L7.96855 12.9769Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </section>

          <section className="profit-reseller profit-story">
            <div className="container">
              <h1 className="text-center">
                <span>Keunggulan</span> <br /> ASI Booster MamaBear
              </h1>

              <div className="profit-story-wrapper">
                <div className="left">
                  <img src="images/story-big-img-2.png" alt="" />
                  <img className="daun" src="/svg/daun.svg" alt="" />
                </div>
                <div className="right">
                  <div className="profit-reseller-wrapper our-story">
                    <ProfitItem
                      titleProfit={ "Kualitas Menyeluruh" }
                      imgProfit={ "1" }
                      desc="Prioritas kami adalah bahan alami terbaik yang terbukti secara
                      ilmiah meningkatkan produksi ASI. serta disempurnakan dari segi
                      teknologi, higienitas dan produk akhir."
                    />
                    <ProfitItem
                      titleProfit={ "Bersertifikat Halal & BPOM" }
                      imgProfit={ "2" }
                      desc="Komitmen kami pada kualitas solusi menyusui, setiap produk kami teruji dan tersertifikasi Halal & BPOM. Temukan informasi produk dan anjuran konsumsi untuk hasil terbaik."
                    />
                    <ProfitItem
                      titleProfit={ "Bersertifikat Halal & BPOM" }
                      imgProfit={ "3" }
                      desc="Mamabear adalah 100% produk lokal. Kami bangga akan penggunaan bahan-bahan Nusantara yang padat nutrisi dan baik untuk kesehatan Anda."
                    />
                    <ProfitItem
                      titleProfit={ "Rasa Lezat" }
                      imgProfit={ "4" }
                      desc="Nutrisi akan bekerja maksimal dengan citarasa lezat. Melalui inovasi & teknik produksi, kami mampu menghantarkan minuman & makanan yang lezat, lengkap dengan seluruh kandungan nutrisinya."
                    />
                    <ProfitItem
                      titleProfit={ "Komunitas Mama Menyusui" }
                      imgProfit={ "5" }
                      desc="Bukan hanya sekedar jualan, Mamabear Indonesia adalah platform lengkap dengan edukasi dan komunitas mama menyusui yang saling mendukung dan berbagi."
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}

      {/* <button onClick={handlePageClick}>next</button> */}
      {/* <div
        ref={targetRef}
        className={`story ${isOddIndex ? "odd-bg" : ""}`}
        onClick={handlePageClick}
      >
        <div className="wrapper">
          <div className="content">
            <div className="content-image">
              <div
                className={`text-title ${isOddIndex ? "odd" : ""}`}
                onClick={handlePageClick}
              >
                <h2>
                  Perjalanan <br /> <span>Mama Bear </span>Indonesia
                </h2>
              </div>
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt=""
                  className={
                    activeIndex === index ? "active-image" : "hidden-image"
                  }
                />
              ))}
            </div>

            <div className="content-year">
              {contentItems.map((item, index) => (
                <div
                  key={index}
                  className={`content-year-items ${
                    activeIndex === index ? "active" : ""
                  } ${
                    activeIndex === index && index % 2 !== 0 ? "odd-active" : ""
                  } ${isOddIndex ? "odd" : ""}`}
                  // onClick={ () => handleItemClick(index) }
                >
                  <div className="desc">
                    <h2>{item.year}</h2>
                    <p className="title">{item.title}</p>
                    <p>{item.description}</p>
                  </div>
                  <div
                    className={`year ${isOddIndex ? "odd" : ""}`}
                    onClick={handlePageClick}
                  >
                    {item.year}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="story-wrapper">
        {images.map((value) => (
          <img src={value} alt="" />
        ))}
      </div> */}

      <div className="story-new">
        <div className="story-item-wrapper-mobile">
          <div className="story-item">
            <h1>
              Perjalanan <br /> <span>Mama Bear </span>Indonesia
            </h1>
            <img src="images/story1-crop.png" alt="" />

            <div className="content-year">
              <div className="desc">
                <h2>2014</h2>
                <p className="title">
                  Berawal dari Gagal Menyusui Anak Pertama
                </p>
                <p>
                  Agnes (Founder MamaBear) melakukan personal riset dan
                  menformulasi produk MamaBear Teh Pelancar ASI dari dapur
                  orangtuanya.
                </p>
              </div>
            </div>
          </div>
          <div className="story-item">
            <h1 className="dark">
              Perjalanan <br /> <span>Mama Bear </span>Indonesia
            </h1>
            <img src="images/story2-crop.png" alt="" />

            <div className="content-year">
              <div className="desc">
                <h2>2016</h2>
                <p className="title">Konsultasi Menyusui Gratis 24 Jam</p>
                <p>
                  MamaBear mendampingi perjalanan menyusui Mama dengan
                  menghadirkan Konsultasi Gratis bersama dengan Konselor
                  Menyusui yang sudah tersertifikasi.
                </p>
              </div>
            </div>
          </div>
          <div className="story-item">
            <h1>
              Perjalanan <br /> <span>Mama Bear </span>Indonesia
            </h1>
            <img src="images/story3-crop.png" alt="" />

            <div className="content-year">
              <div className="desc">
                <h2>2018</h2>
                <p className="title">
                  MamaBear Membantu 1.000.000++ Ibu Menyusui di Indonesia
                </p>
                <p>
                  Dengan produk yang berkualitas yang sudah tersertifikasi HALAL
                  dan BPOM, serta pendistribusian produk yang merata. Kami bisa
                  membantu lebih dari 1.000.000 Ibu Menyusui di Indonesia.
                </p>
              </div>
            </div>
          </div>
          <div className="story-item">
            <h1 className="dark">
              Perjalanan <br /> <span>Mama Bear </span>Indonesia
            </h1>
            <img src="images/story5-crop.png" alt="" />

            <div className="content-year">
              <div className="desc">
                <h2>2021</h2>
                <p className="title">Pertama kali mendapatkan Brand Choice</p>
                <p>
                  Dengan 3.000.000 ++ Ibu Menyusui di Indonesia yang terbantu
                  dengan produk MamaBear, di tahun ini kami menerima penghargaan
                  Brand Choice pertama kalinya.
                </p>
              </div>
            </div>
          </div>
          <div className="story-item">
            <h1>
              Perjalanan <br /> <span>Mama Bear </span>Indonesia
            </h1>
            <img src="images/story6-crop.png" alt="" />

            <div className="content-year">
              <div className="desc">
                <h2>2023</h2>
                <p className="title">
                  Berawal dari Gagal Menyusui Anak Pertama
                </p>
                <p>
                  MamaBear mendapatkan Brand Choice selama 3 tahun
                  berturut-turut tahun 2021-2023. Dan juga Brand Choice Mom n
                  Kids tahun 2022 - 2023. Dengan kepercayaan yang tinggi dari
                  customer, MamaBear sudah membantu 5.000.000++ Ibu Menyusui di
                  Indonesia.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="story-item-wrapper-desktop">
          <h1>
            Perjalanan <br /> <span>Mama Bear </span>Indonesia
          </h1>
          <div className="images" ref={imageRef}>
            {images.map((value, index) => (
              <section key={index}>
                <img src={value} alt="" />
              </section>
            ))}
          </div>

          <div className="content-year parent-content-year">
            {contentItems.map((item, index) => (
              <div
                key={index}
                className={`content-year-items ${
                  activeIndex === index ? "active" : ""
                } ${
                  activeIndex === index && index % 2 !== 0 ? "odd-active" : ""
                } ${isOddIndex ? "odd" : ""}`}
                // onClick={ () => handleItemClick(index) }
              >
                <div className="desc">
                  <h2>{item.year}</h2>
                  <p className="title">{item.title}</p>
                  <p>{item.description}</p>
                </div>
                <div
                  className={`year ${isOddIndex ? "odd" : ""}`}
                  onClick={handlePageClick}
                >
                  {item.year}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
