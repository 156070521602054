import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";

interface Setting {
  application_fee?: string;
  terms_conditions?: string;
  privacy_policy?: string;
}

type SettingStore = {
  setting: Setting;
  fetchSetting: () => void;
};

const settingStore = create<SettingStore>()(
  persist(
    (set, get) => ({
      setting: {},
      fetchSetting: async () => {
        const res = await Network.get(Api.dmSetting, {});
        if (res?.ok ?? false) {
          const body = await res!.json();
          const setting = body["data"];
          set({ setting: setting });
        }
      },
    }),
    {
      name: "setting-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default settingStore;
