import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import GoogleLoginButton from "../components/google_login_button";
import TextInput from "../../shared/components/text_input";
import { useState } from "react";
import authFacade from "../facades/auth_facade";

function RegisterScreen() {
  const navigate = useNavigate();
  const [activeTab, setactiveTab] = useState(0);
  const { registerForm, setRegisterField, register } = authFacade();
  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    setRegisterField(name as keyof typeof registerForm, value);
  };
  return (
    <div id="auth-root" className="login">
      <div className="header">
        <img src={logo} alt="" />
        <h1>Registrasi Akun</h1>
        <p>
          Yuk, Registrasi agar kamu <br /> bisa mulai membeli produk !
        </p>
      </div>
      <div className="tab">
        <button
          className={activeTab == 0 ? "active" : ""}
          onClick={function () {
            setactiveTab(0);
          }}
        >
          Email
        </button>
        <button
          className={activeTab == 1 ? "active" : ""}
          onClick={function () {
            setactiveTab(1);
          }}
        >
          Nomor Handphone
        </button>
      </div>
      {activeTab == 0 ? (
        <TextInput
          isObsecured={false}
          name={"email"}
          value={registerForm.email}
          onChange={handleInputChange}
          label={"Email"}
          placeholder={"Masukkan email"}
          text={"Contoh : john@email.com"}
        />
      ) : (
        <TextInput
          isObsecured={false}
          name={"phone"}
          value={registerForm.phone}
          onChange={handleInputChange}
          label={"Nomor Handphone"}
          placeholder={"Masukkan nomor handpone"}
          text="Contoh : 8123456789"
        />
      )}
      <div className="height-20"></div>
      <div className="height-15"></div>
      <div className="height-15"></div>
      <div
        onClick={() =>
          register(registerForm, activeTab == 0 ? "email" : "phone").then(
            (response) => {
              // console.log(response);
              if (response.status) {
                if (!response.isOtp) {
                  navigate("/store/otp?register=true", {
                    replace: true,
                    state:
                      activeTab == 0
                        ? { email: registerForm.email, type: "email" }
                        : { phone: registerForm.phone, type: "phone" },
                  });
                } else {
                  navigate("/store/register-completion", {
                    state:
                      activeTab == 0
                        ? { email: registerForm.email, type: "email" }
                        : { phone: registerForm.phone, type: "phone" },
                  });
                }
              }
            }
          )
        }
        className="primary-button"
      >
        Daftar
      </div>
      <div className="divider">
        <span></span>
        Atau masuk dengan
        <span></span>
      </div>
      <GoogleLoginButton />
      <div className="height-13"></div>
      <p className="actions">
        Sudah punya akun?
        <Link to="/store/login">
          <span>Masuk</span>
        </Link>
      </p>
    </div>
  );
}
export default RegisterScreen;
