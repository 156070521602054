import { MouseEventHandler } from "react";
import dummyVoucher from "../../../assets/images/dummy-voucher.png";
import { MembershipModel, Voucher } from "../models/membership_model";
import { UserVoucher } from "../models/voucher_model";

interface VoucherItemProps {
  totalItems?: number;
  voucher?: MembershipModel;
  isFlexible?: boolean;
  isClaimed?: boolean;
  showButton?: boolean;
  onActionTap: MouseEventHandler;
  isFromMembership?: boolean;
}
function VoucherItem(props: VoucherItemProps) {
  function formatExDate(start?: string, end?: string): string {
    const startDate = new Date(start ?? "2023-11-01");
    const endDate = new Date(end ?? "2023-11-15");
    if (startDate.getFullYear() == endDate.getFullYear()) {
      if (startDate.getMonth() == endDate.getMonth()) {
        return `${start?.split(" ")[0]}-${end}`;
      }
      return `${start?.split(" ")[0]} ${start?.split(" ")[1]}-${end}`;
    }
    return `${start} - ${end}`;
  }
  return (
    <div className="voucher-item">
      <div className="row">
        <figure>
          <img src={props.voucher?.voucher?.image_url_text ?? dummyVoucher} />
        </figure>
        <div className="voucher-content">
          <div className={props.isFlexible ?? true ? "expanded" : ""}>
            <h6>{props.voucher?.voucher?.name ?? "-"}</h6>
            <span>
              Maks.{" "}
              {props.voucher?.voucher?.value_type == "percent"
                ? `${props.voucher?.voucher?.value}%`
                : `${(props.voucher?.voucher?.value ?? 0) / 1000}K`}
            </span>
            {props.showButton ?? true ? (
              <button
                className={
                  props.voucher?.claimed_at != null ?? props.isClaimed ?? false
                    ? "disabled"
                    : ""
                }
                disabled={props.isClaimed}
                onClick={props.onActionTap}
              >
                {props.voucher?.claimed_at != null ?? props.isClaimed ?? false
                  ? props.isFromMembership
                    ? "Voucher Sudah Diklaim"
                    : "Gunakan Voucher"
                  : "Klaim Voucher"}
              </button>
            ) : (
              <>
                <br />
                <span>S&K Berlaku</span>
              </>
            )}
          </div>
          <div className={props.isFlexible ?? true ? "expanded" : ""}>
            <span className="info">
              {props.voucher?.voucher?.voucher_category_name_text ??
                "Semua Kategori"}
            </span>
            <span className="info">
              {`Min. Belanja ${
                (props.voucher?.voucher?.minimum_payment ?? 0) / 1000
              } K`}
            </span>
            <span className="info">
              {formatExDate(
                props.voucher?.voucher?.start_date_text,
                props.voucher?.voucher?.end_date_text
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoucherItem;
