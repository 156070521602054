import { ReactNode } from 'react';
import emptyIcon from '../../../assets/images/empty_cart.svg';
interface EmptyProps {
    icon?: string,
    title?: string,
    subtitle?: string,
    children?: ReactNode
}
function EmptyState(props: EmptyProps) {
    return (
        <div className="empty-state">
            <img src={props.icon ?? emptyIcon} />
            <h5>
                {props.title ?? " Keranjang Kosong"}
            </h5>
            <p dangerouslySetInnerHTML={{
                __html: props.subtitle ?? `Mulai tambahkan item<br/>untuk menikmati belanja!`
            }}>
            </p>
            {
                props.children
            }
        </div>
    )
}

export default EmptyState;