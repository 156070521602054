import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";
import { MamaPointPagination } from "../models/mama_point_model";

type MamaPointStore = {
  mamaPoint: MamaPointPagination;
  fetchMamaPoint: () => void;
};

const mamaPointStore = create<MamaPointStore>()(
  persist(
    (set, get) => ({
      mamaPoint: {},
      fetchMamaPoint: async () => {
        const res = await Network.get(Api.userPoints, {});
        if (res?.ok ?? false) {
          const body = await res!.json();
          const mamaPoint = body;
          set({ mamaPoint: mamaPoint });
        }
      },
    }),
    {
      name: "mama-point-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default mamaPointStore;
