import { create } from "zustand";
import { NOtificationPagination } from "../models/notification_model";
import { persist } from "zustand/middleware";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";
import Toast from "../../../utils/toast";

type NotificationStore = {
  notifications: NOtificationPagination;
  fetchNotifications: () => void;
  readNotification: (id: string) => Promise<boolean>;
};

const notificationStore = create<NotificationStore>()(
  persist(
    (set, get) => ({
      notifications: {},
      fetchNotifications: async () => {
        const res = await Network.get(Api.notifications, {});
        if (res?.ok ?? false) {
          const body = await res!.json();
          const notifications = body["data"];
          set({ notifications: notifications });
        }
      },
      readNotification: async (id) => {
        const res = await Network.post(
          Api.notificationRead.replace(":id", id),
          { data: {}, useToken: true }
        );
        if (res?.ok ?? false) {
          const body = await res!.json();
          Toast.show(body["message"], {});
          return true;
        }

        return false;
      },
    }),
    {
      name: "notification-storage",
      getStorage: () => sessionStorage,
    }
  )
);

export default notificationStore;
