import { ChangeEvent, useEffect, useState } from "react";
import BottomSheet from "../../../shared/components/bottom_sheet";
import TextInput from "../../../shared/components/text_input";
import uploadPlaceholder from "../../../../assets/images/upload_file_large.svg";
import { ComplainDocument } from "../../models/complain_model";
import Toast from "../../../../utils/toast";

interface ProofUploadProp {
  open?: boolean;
  onClose?: () => void;
  onSubmit?: (complain: ComplainDocument) => void;
}

function ProofUploadDialog(props: ProofUploadProp) {
  const [image, setImage] = useState<File | null>(null);
  const [description, setDescription] = useState<string>("");

  function handleImageChange(e: ChangeEvent<HTMLInputElement>) {
    const selectedImage = e.target.files?.[0] || null;
    setImage(selectedImage);
  }

  const handleSubmit = () => {
    if (image == null) {
      Toast.show("Unggah bukti terlebih dahulu", {});
      return;
    }

    if (description.length < 0) {
      Toast.show("Isi Deskripsi", {});
      return;
    }

    setImage(null);
    setDescription("");

    props.onSubmit?.({
      file: image,
      description: description,
    });
  };

  return (
    <BottomSheet open={props.open} onClose={props.onClose} title="Unggah Bukti">
      <div className="column" style={{ gap: 15 }}>
        <figure className="proof-file">
          <img
            src={image != null ? URL.createObjectURL(image) : uploadPlaceholder}
          />
          <div className="icon"></div>
          <input type="file" accept="image/*" onChange={handleImageChange} />
        </figure>
        <TextInput
          isOutlined
          isMultiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Jelaskan masalah"
          text="Minimal 20 Karakter"
          label="Deskripsi"
        ></TextInput>
        <div className="row" style={{ gap: 10 }}>
          <div className="expanded primary-button" onClick={handleSubmit}>
            Unggah
          </div>
          <div
            className="expanded secondary-button"
            onClick={() => props.onClose}
          >
            Batal
          </div>
        </div>
      </div>
    </BottomSheet>
  );
}

export default ProofUploadDialog;
