import { shallow } from "zustand/shallow";
import orderStore from "../store/order_store";

const orderFacade = () => {
  const {
    orders,
    order,
    courierFee,
    tracking,
    fetchOrders,
    fetchOrderDetail,
    storeOrder,
    fetchCourierFee,
    storeOrderGuest,
    fetchCourierFeeGuest,
    cancelOrder,
    cancelOrderCancel,
    acceptOrder,
    trackingOrder,
    complainOrder,
  } = orderStore(
    (state) => ({
      orders: state.orders,
      order: state.order,
      courierFee: state.courierFee,
      tracking: state.tracking,
      fetchOrderDetail: state.fetchOrderDetail,
      fetchOrders: state.fetchOrders,
      storeOrder: state.storeOrder,
      fetchCourierFee: state.fetchCourierFee,
      storeOrderGuest: state.storeOrderGuest,
      fetchCourierFeeGuest: state.fetchCourierFeeGuest,
      cancelOrder: state.cancelOrder,
      cancelOrderCancel: state.cancelOrderCancel,
      acceptOrder: state.acceptOrder,
      trackingOrder: state.trackingOrder,
      complainOrder: state.complainOrder,
    }),
    shallow
  );

  return {
    orders,
    order,
    courierFee,
    tracking,
    fetchOrders,
    fetchOrderDetail,
    storeOrder,
    fetchCourierFee,
    storeOrderGuest,
    fetchCourierFeeGuest,
    cancelOrder,
    cancelOrderCancel,
    acceptOrder,
    trackingOrder,
    complainOrder,
  };
};

export default orderFacade;
