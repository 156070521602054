import { create } from "zustand";
import { PaymentMethodModel } from "../models/payment_method_model"
import { createJSONStorage, persist } from "zustand/middleware";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";

type PaymentMethodStore = {
    paymentMethods: PaymentMethodModel[];
    fetchPaymentMethod: () => void;
}

const paymentMethodStore = create<PaymentMethodStore>()(
    persist((set, get) => ({
        paymentMethods: [],
        fetchPaymentMethod: async () => {
            const res = await Network.get(Api.paymentMethod, {});
            if (res?.ok ?? false) {
                const body = await res!.json()
                const paymentMethods = body['data']
                set({ paymentMethods: paymentMethods })
            }
        }
    }), {
        name: "payment-method-storage",
        storage: createJSONStorage(() => sessionStorage)
    })
)

export default paymentMethodStore