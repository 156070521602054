import { ReactNode, useEffect, useState } from "react";
import BottomSheet from "../../../shared/components/bottom_sheet";
import ProofUploadDialog from "./proof_upload_dialog";

interface ProblemsTypeProp {
  open?: boolean;
  onClose?: () => void;
  onSubmit?: (reason: ReasonModel) => void;
}

interface ReasonModel {
  id: number;
  issue: string;
  text: string;
}

const statusReasons: ReasonModel[] = [
  {
    id: 1,
    issue: "not_received",
    text: "Produk tidak diterima",
  },
  {
    id: 2,
    issue: "received_but_broken",
    text: "Produk rusak",
  },
  {
    id: 3,
    issue: "not_as_ordered",
    text: "Produk tidak sesuai pesanan",
  },
];

function ProblemsTypeDialog(props: ProblemsTypeProp) {
  const [openWa, setOpenWa] = useState(false);
  return (
    <BottomSheet
      open={props.open}
      onClose={props.onClose}
      title="Jenis Masalah"
    >
      <div className="more-options" style={{ gap: 15, padding: 5 }}>
        {statusReasons.map((reason) => (
          <div
            className="option"
            onClick={() => props.onSubmit && props.onSubmit(reason)}
          >
            <p>{reason.text}</p>
          </div>
        ))}
      </div>
    </BottomSheet>
  );
}

export default ProblemsTypeDialog;
