import courierStore from "../store/courier_store";

const CourierFacade = () => {

    const {couriers, fetchCouriers} = courierStore(
        (state) => ({
            couriers: state.couriers,
            fetchCouriers: state.fetchCouriers,
        })
    )
    
    return {couriers, fetchCouriers};
}

export default CourierFacade;