import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
// import './assets/styles/main.scss';
import Auth from "./module/authentication/view";
import LoginScreen from "./module/authentication/view/login_screen";
import "./assets/css/main.css";
import VerificationMethodScreen from "./module/authentication/view/verification_method_screen";
import VerificationCodeScreen from "./module/authentication/view/verification_code_screen";
import TncPP from "./module/authentication/view/tnc_pp";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
  useSearchParams,
  useNavigate,
  RouteProps,
} from "react-router-dom";
import NotificationScreen from "./module/notification/view/notification_screen";
import DashboardScreen from "./module/dashboard/view/dashboard_screen";
import "react-spring-bottom-sheet/dist/style.css";
import ProductDetail from "./module/product/view/product_detail";
import ReviewDetail from "./module/product/view/review_detail";
import CartPage from "./module/order/view/cart_page";
import CheckoutPage from "./module/order/view/checkout_page";
import VoucherPage from "./module/order/view/voucher_page";
import PaymentMethodPage from "./module/order/view/payment_method_page";
import PaymentPage from "./module/order/view/payment_page";
import PaymentGuidePage from "./module/order/view/payment_guide_page";
import HomeScreen from "./module/order/view/home_screen";
import AddressPage from "./module/order/view/home/address/address_page";
import AddAddressPage from "./module/order/view/home/address/add_address_page";
import PinAddressPage from "./module/order/view/home/address/pin_address_page";
import MamaPointPage from "./module/order/view/home/membership/mama_point_page";
import MembershipPage from "./module/order/view/home/membership/membership_page";
import MembershipListPage from "./module/order/view/home/membership/membership_list_page";
import TransactionDetailPage from "./module/transaction/view/transaction_detail_page";
import InvoicePage from "./module/transaction/view/invoice_page";
import TrackingPage from "./module/transaction/view/tracking_page";
import ReviewPage from "./module/transaction/view/review_page";
import ReviewDetailPage from "./module/transaction/view/review_detail_page";
import ProblemsDetailPage from "./module/transaction/view/problems_detail_page";
import RegisterFormScreen from "./module/authentication/view/register_form_screen";
import RegisterScreen from "./module/authentication/view/register_screen";
import SearchPage from "./module/dashboard/view/search_page";
import LandingPage from "./module/landing/landing_page";
import routes from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { GoogleOAuthProvider } from "@react-oauth/google";
import toast, { Toaster } from "react-hot-toast";
import { onFcmListener } from "./firebase";
import orderFacade from "./module/order/facades/order_facade";

const route = {
  home: "home",
  search: "home/search",
  address: "home/address",
  transactionDetail: "home/transaction-detail",
  invoice: "home/invoice",
  tracking: "home/tracking",
  problemsDetail: "home/problems-detail",
  review: "home/review",
  reviewDetail: "home/review-detail",
  addAddress: "home/address/add",
  editAddress: "home/address/edit",
  pinAddress: "home/address/add/pin",
  mamaPoint: "home/mama-point",
  membership: "home/membership",
  listMembership: "home/membership/list",
  login: "login",
  register: "register",
  registerCompletion: "register-completion",
  productDetails: "product-details",
  reviewDetails: "review-details",
  cart: "cart",
  voucher: "voucher",
  checkout: "checkout",
  payment: "payment",
  paymentMethod: "payment-method",
  paymentGuide: "payment-guide",
  notifications: "notifications",
  verification: "verification",
  otp: "otp",
  termsCondition: "terms-condition",
  privacyPolicy: "privacy-policy",
  about: "about",
};

interface StatusModel {
  text: string;
  label: string;
}

const statusMap: { [key: string]: StatusModel } = {
  processed: {
    text: "Pesanan di proses",
    label: "Diproses",
  },
  need_process: {
    text: "Pesanan di menunggu diproses",
    label: "Menunggu Diproses",
  },
  sending: {
    text: "Pesanan sedang dalam proses pengiriman",
    label: "Dikirim",
  },
  unpaid: {
    text: "Pesanan menunggu dibayar",
    label: "Menunggu Pembayaran",
  },
  cancel_wait: {
    text: "Menunggu proses pembatalan",
    label: "Pengajuan Pembatalan",
  },
  cancelled: {
    text: "Pesanan dibatalkan oleh MamaBear",
    label: "Dibatalkan",
  },
  finished: {
    text: "Pesanan belum diulas",
    label: "Diterima",
  },
};

function Marketplace() {
  const navigate = useNavigate();
  const { order, fetchOrderDetail } = orderFacade();

  onFcmListener()
    .then((payload) => {
      toast(
        <span style={{ textAlign: "center" }}>
          <b>{payload.data.title}</b>
          <br />
          {payload.data.body}
        </span>
      );

      if (payload.data.screen.includes("ORDER")) {
        let orderId = parseInt(payload.data.screen.replace("ORDER-", ""));
        fetchOrderDetail(orderId);
        navigate(routes.transactionDetail, {
          state: { order: order, status: statusMap[order.data?.status ?? ""] },
        });
      }
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <>
      <Toaster />
      <Outlet />
    </>
  );
}

function GoogleAuth() {
  const [queryParameters] = useSearchParams();

  const navigate = useNavigate();
  useEffect(function () {
    if (!!queryParameters.get("access_token")) {
      localStorage.setItem("token", queryParameters.get("access_token") ?? "");
      navigate(routes.home, { replace: true });
    }
  }, []);
  return <div>{/* {queryParameters.get('access_token')} */}</div>;
}

function ShouldAuthenticatedMiddleware(
  next: () => void,
  redirect: (val: string) => void
) {
  if (localStorage.getItem("token") != undefined) {
    redirect(routes.home);
  }
  next();
}

function GuestOnly(next: () => void, redirect: (val: string) => void) {
  if (localStorage.getItem("token") == undefined) {
    next();
  }
}

const router = createBrowserRouter([
  {
    path: "/*",
    element: <LandingPage />,
  },
  {
    path: "/store",
    element: <Marketplace />,
    children: [
      {
        path: "google-auth",
        element: (
          <GuardedRoute
            element={<GoogleAuth />}
            middleware={ShouldAuthenticatedMiddleware}
          />
        ),
      },
      {
        path: route.home,
        element: <HomeScreen />,
      },
      {
        path: route.search,
        element: <SearchPage />,
      },
      {
        path: route.address,
        element: <AddressPage />,
      },
      {
        path: route.transactionDetail,
        element: <TransactionDetailPage />,
      },
      {
        path: route.invoice,
        element: <InvoicePage />,
      },
      {
        path: route.tracking,
        element: <TrackingPage />,
      },
      {
        path: route.problemsDetail,
        element: <ProblemsDetailPage />,
      },
      {
        path: route.review,
        element: <ReviewPage />,
      },
      {
        path: route.reviewDetail,
        element: <ReviewDetailPage />,
      },
      {
        path: route.addAddress,
        element: <AddAddressPage />,
      },
      {
        path: route.editAddress,
        element: <AddAddressPage />,
      },
      {
        path: route.pinAddress,
        element: <PinAddressPage />,
      },
      {
        path: route.mamaPoint,
        element: <MamaPointPage />,
      },
      {
        path: route.membership,
        element: <MembershipPage />,
      },
      {
        path: route.listMembership,
        element: <MembershipListPage />,
      },
      {
        path: route.login,
        element: (
          <GuardedRoute
            element={<LoginScreen />}
            middleware={ShouldAuthenticatedMiddleware}
          />
        ),
      },
      {
        path: route.register,
        element: (
          <GuardedRoute
            element={<RegisterScreen />}
            middleware={ShouldAuthenticatedMiddleware}
          />
        ),
      },
      {
        path: route.otp,
        element: (
          <GuardedRoute
            element={<VerificationCodeScreen />}
            middleware={ShouldAuthenticatedMiddleware}
          />
        ),
      },
      {
        path: route.verification,
        element: (
          <GuardedRoute
            element={<VerificationMethodScreen />}
            middleware={ShouldAuthenticatedMiddleware}
          />
        ),
      },
      {
        path: route.registerCompletion,
        element: <RegisterFormScreen />,
      },
      {
        path: route.productDetails,
        element: <ProductDetail />,
      },
      {
        path: route.reviewDetails,
        element: <ReviewDetail />,
      },
      {
        path: route.cart,
        element: <CartPage />,
      },
      {
        path: route.voucher,
        element: <VoucherPage />,
      },
      {
        path: route.checkout,
        element: <CheckoutPage />,
      },
      {
        path: route.paymentMethod,
        element: <PaymentMethodPage />,
      },
      {
        path: route.payment,
        element: <PaymentPage />,
      },
      {
        path: route.paymentGuide,
        element: <PaymentGuidePage />,
      },
      {
        path: route.notifications,
        element: <NotificationScreen />,
      },
      {
        path: route.termsCondition,
        element: <TncPP title="Syarat dan Ketentuan" />,
      },
      {
        path: route.privacyPolicy,
        element: <TncPP title="Kebijakan Privasi" />,
      },
      {
        path: route.about,
        element: <div>About</div>,
      },
    ],
  },
]);

interface GuardedRouteProps {
  element: JSX.Element;
  middleware: (next: () => void, redirect: (val: string) => void) => void;
}

function GuardedRoute(props: GuardedRouteProps) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  useEffect(function () {
    props.middleware(
      () => setShow(true),
      (path) => navigate(path, { replace: true })
    );
  }, []);
  return show ? props.element : <div></div>;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <GoogleOAuthProvider clientId="703610309646-b0hd1f3ukn2egblevmcqks465f047jcp.apps.googleusercontent.com">
      <RouterProvider router={router} />
    </GoogleOAuthProvider>
  </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
