import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { UserModel } from "../models/user_model";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";
import Toast from "../../../utils/toast";

type ProfileStore = {
  uploadProgress: number;
  user: UserModel;
  fetchUser: () => Promise<void>;
  uploadImage: (image: File | null) => Promise<void>;
  updateUser: (obj: { [key: string]: any }) => Promise<boolean>;
};

const profileStore = create<ProfileStore>()(
  persist(
    (set, get) => ({
      uploadProgress: 0,
      user: {
        id: 0,
        name: "",
        email: "",
        phone: "",
        phoneCode: "",
        isMale: false,
        isProfileCompleted: false,
      },
      uploadImage: async (image) => {
        if (image) {
          const formData = new FormData();
          formData.append("_method", "PUT");
          formData.append("image", image);

          const xhr = new XMLHttpRequest();
          xhr.open("POST", `${Api.getUser}/update`, true);
          xhr.setRequestHeader(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
          );
          xhr.setRequestHeader("Accept", "application/json");

          xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
              const progress = (event.loaded / event.total) * 100;
              set({
                uploadProgress: progress,
              });
            }
          });

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                // console.log('File uploaded successfully:', xhr.responseText);
              }

              setTimeout(() => {
                set((state) => {
                  state.fetchUser();
                  return { uploadProgress: 0 };
                });
              }, 1000);
              const jsonResponse = JSON.parse(xhr.responseText);
              Toast.show(jsonResponse["message"] ?? "", {});
            }
          };

          xhr.send(formData);
        }
      },
      fetchUser: async () => {
        const res = await Network.get(Api.getUser, {});
        if (res?.ok ?? false) {
          const body = await res!.json();
          const user = body["data"];
          set({ user: user });
        }
      },
      updateUser: async (form) => {
        const data: { [key: string]: any } = {};

        for (const key in form) {
          if (form[key] !== null && form[key] !== "") {
            data[key] = form[key];
          }
        }

        const res = await Network.post(`${Api.getUser}/update`, {
          data: { _method: "PUT", ...data },
        });
        const body = await res.json();
        if (res.ok) {
          const user = body["data"];
          set({ user: user });
          Toast.show(body["message"] ?? "", {});
          return true;
        }
        Toast.show(body["message"] ?? "", {});
        return false;
      },
    }),
    {
      name: "profile-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default profileStore;
