import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../../../api/api-client";

const getCareersFn = async (page, keyword, divisionId, type) => {
  const response = await apiClient.get(
    `/v1/careers?keyword=${keyword}&career_division_id=${divisionId}&page=${page}&type=${type}`
  );
  return response.data.data;
};

export function useCareers(page = 1, keyword = "", divisionId = "", type = "") {
  return useQuery({
    queryKey: [`useCareers ${page}, ${keyword}, ${divisionId}, ${type}`],
    queryFn: () => getCareersFn(page, keyword, divisionId, type),
    enabled: true, // to disable this query from automatically running when queryKey changes
  });
}
