import { useEffect, useState } from "react";

interface RadioProps {
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

function Radio(props: RadioProps) {
    const [checked, setChecked] = useState(false);

    useEffect(
        () => {
            setChecked(props.checked ?? false)
        }, [props.checked]
    )

    return (
        <div className='radio'>
            <input type="radio" checked={props.checked ?? false} onChange={(e) => props.onChange?.(e.target.checked)} />
            <span className="checkmark"></span>
        </div>
    )
}

export default Radio

