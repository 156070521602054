import { create } from 'zustand'
import { createJSONStorage, persist } from "zustand/middleware"

type HomeStore = {
    activeMenu: string;
    setActiveMenu: (menu: string) => void;
}

const homeStore = create<HomeStore>()(
    persist((set, get) => ({
        activeMenu: "beranda",
        setActiveMenu: (menu) => set({ activeMenu: menu }),
    }),
        {
            name: "home-storage",
            storage: createJSONStorage(() => sessionStorage)
        }
    ),
)

export default homeStore