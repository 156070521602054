import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import orderFacade from "../../order/facades/order_facade";
import moment from "moment";

function TrackingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const orderId = location.state.orderId as number;
  const { tracking, trackingOrder } = orderFacade();

  const testing = {
    tracking: [
      { address: "Jalan A, Kota A", lat: -7.12345, lng: 112.34567 },
      { address: "Jalan B, Kota B", lat: -7.23456, lng: 112.45678 },
      { address: "Jalan C, Kota C", lat: -7.34567, lng: 112.56789 },
      // ...
    ],
  };

  useEffect(() => {
    trackingOrder(orderId);
  }, []);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    // Check if the Google Maps script is already loaded
    if (
      typeof window.google === "undefined" ||
      typeof window.google.maps === "undefined"
    ) {
      // If not loaded, dynamically load the Google Maps script
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBCUKePgvazZmgP2Z0n46FQPDVS8UNPJ30&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initializeMap;
      document.head.appendChild(script);
    } else {
      // If already loaded, initialize the map immediately
      initializeMap();
    }
  }, []);

  const initializeMap = () => {
    if (tracking && tracking.tracking && tracking.tracking.length > 0) {
      const mapOptions = {
        zoom: 20,
        center: new window.google.maps.LatLng(
          parseFloat(tracking?.origin_address_json?.latitude ?? ""),
          parseFloat(tracking?.origin_address_json?.longitude ?? "")
        ),
      };

      const mapInstance = new window.google.maps.Map(
        document.getElementById("map")!,
        mapOptions
      );

      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer({
        map: mapInstance,
      });

      const request = {
        origin: new window.google.maps.LatLng(
          parseFloat(tracking.origin_address_json?.latitude ?? ""),
          parseFloat(tracking.origin_address_json?.longitude ?? "")
        ),
        destination: new window.google.maps.LatLng(
          parseFloat(tracking.destination_address_json?.latitude ?? ""),
          parseFloat(tracking.destination_address_json?.longitude ?? "")
        ),
        travelMode: window.google.maps.TravelMode.DRIVING,
      };

      directionsService.route(request, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
        }
      });
    }
  };

  return (
    <div className="page tracking-page">
      <div className="header">
        <svg
          className="back"
          onClick={() => navigate(-1)}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 8.5L6 12.5M6 12.5L10 16.5M6 12.5L18 12.5"
            stroke="#191919"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>Lacak Pesanan</p>
        <span className="action">{tracking.code}</span>
      </div>
      <div className="page-content">
        <div id="map" style={{ height: "100%", width: "100%" }}></div>
        <div className="progress-section">
          <div className="column">
            <div className="row">
              <div className="label">Status Pesanan</div>
              <div className="label">Estimasi Diterima</div>
            </div>
            <div className="row">
              <div className="status">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="10.625"
                    cy="11.25"
                    r="1.25"
                    stroke="#DE8D8D"
                    strokeWidth="0.9375"
                  />
                  <circle
                    cx="4.375"
                    cy="11.25"
                    r="1.25"
                    stroke="#DE8D8D"
                    strokeWidth="0.9375"
                  />
                  <path
                    d="M3.125 11.2328C2.43955 11.1986 2.01194 11.0966 1.70765 10.7924C1.40335 10.4881 1.30139 10.0605 1.26722 9.375M5.625 11.25H9.375M11.875 11.2328C12.5605 11.1986 12.9881 11.0966 13.2924 10.7924C13.75 10.3347 13.75 9.59814 13.75 8.125V6.875H10.8125C10.3472 6.875 10.1145 6.875 9.92623 6.81382C9.54568 6.69017 9.24733 6.39182 9.12368 6.01127C9.0625 5.82298 9.0625 5.59032 9.0625 5.125C9.0625 4.42702 9.0625 4.07803 8.97073 3.79559C8.78526 3.22477 8.33773 2.77724 7.76691 2.59177C7.48447 2.5 7.13548 2.5 6.4375 2.5H1.25"
                    stroke="#DE8D8D"
                    strokeWidth="0.9375"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.25 5H5"
                    stroke="#DE8D8D"
                    strokeWidth="0.9375"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.25 6.875H3.75"
                    stroke="#DE8D8D"
                    strokeWidth="0.9375"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.0625 3.75H10.2008C11.1104 3.75 11.5651 3.75 11.9353 3.97107C12.3054 4.19214 12.521 4.59257 12.9522 5.39344L13.75 6.875"
                    stroke="#DE8D8D"
                    strokeWidth="0.9375"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {tracking.tracking?.[0]?.status ?? ""}
              </div>
              <div className="text">
                {moment(tracking.estimated_on_user_at ?? new Date()).format(
                  "DD MMMM YYYY, HH:mm"
                )}
              </div>
            </div>
          </div>
          <div className="progress-line">
            <div className="progress-bar active"></div>
            <div className="progress-bar active"></div>
            <div className="progress-bar"></div>
            <div className="progress-bar"></div>
          </div>
          <div className="progress-container">
            {tracking?.tracking?.map((item) => (
              <div className="progress">
                <h5>
                  {moment(item.updated_at ?? new Date()).format(
                    "DD MMMM YYYY, HH:mm"
                  )}
                </h5>
                <p>{item.note}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackingPage;
