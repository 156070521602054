import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDz2xSgfU0NNdEDKeK2EVK9cWAp5h2B_XA",
  authDomain: "mamabear-411504.firebaseapp.com",
  projectId: "mamabear-411504",
  storageBucket: "mamabear-411504.appspot.com",
  messagingSenderId: "556974269152",
  appId: "1:556974269152:web:fa2d78cf0892e8c9fb6685",
  measurementId: "G-7G6CYMDHNN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const getFcmToken = async () => {
  let token = "";
  await getToken(messaging, {
    vapidKey:
      "BDB3pAgMPyi1-Ah3HSOkuzljqNhcRuDdZgOd4NRsLefM6r-tf9W_8RBUV4vUxrJZY8Gs6ZD_INPNq44PzXydih0",
  })
    .then((currentToken) => {
      if (currentToken) {
        token = currentToken;
        // console.log("current token for client: ", currentToken);
      } else {
        // console.log(
        //   "No registration token available. Request permission to generate one."
        // );
      }
    })
    .catch((err) => {
      // console.log(err);
      // console.log("An error occurred while retrieving token. ", err);
    });

  return token;
};

export const onFcmListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
