import { MouseEventHandler, useEffect, useState } from "react";
import membershipFacade from "../facades/membership_facade";
import voucherFacade from "../facades/voucher_facade";
import { useNavigate } from "react-router-dom";
import { MembershipModel } from "../models/membership_model";
import authFacade from "../../authentication/facades/auth_facade";
import routes from "../../../routes";

interface CartSummaryProps {
  actionText?: string;
  showVoucher?: boolean;
  onActionTap: MouseEventHandler;
  price?: number;
  disabled?: boolean;
}

function CartSummary(props: CartSummaryProps) {
  const { vouchers, fetchVouchers } = voucherFacade();
  const { isAuthenticated } = authFacade();

  const [selectedVoucher, setSelectedVoucher] = useState<MembershipModel>(
    JSON.parse(localStorage.getItem("voucher") ?? "{}") as MembershipModel
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      fetchVouchers();
    }
  }, []);

  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  return (
    <div className="cart-summary">
      {props.showVoucher ?? false ? (
        <div
          className="voucher"
          onClick={() =>
            isAuthenticated
              ? navigate("/store/voucher")
              : navigate(routes.login)
          }
        >
          <p>
            {!isAuthenticated ? (
              "Login untuk menggunakan voucher"
            ) : (
              <>
                {Object.keys(selectedVoucher).length != 0 ? (
                  <>
                    Kamu Menghemat{" "}
                    {selectedVoucher.voucher?.value_text_formatted ?? 0}
                    <span>1 Voucher Digunakan</span>
                  </>
                ) : (
                  <>
                    {vouchers?.total == 0
                      ? "Tidak ada voucher tersedia"
                      : vouchers?.total + " voucher tersedia"}
                  </>
                )}
              </>
            )}
          </p>
        </div>
      ) : null}
      <div className="row">
        <div className="price">
          <p>Total Bayar</p>
          <h5>
            <span>Rp</span>
            {numberWithCommas(props.price ?? 0)}
          </h5>
        </div>
        <button disabled={props.disabled} onClick={props.onActionTap}>
          {props.actionText ?? "Checkout"}
        </button>
      </div>
    </div>
  );
}

export default CartSummary;
