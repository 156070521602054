import { apiClient } from "../../../../../api/api-client";
import { useQuery } from "@tanstack/react-query";

const getFaqFn = async () => {
  const response = await apiClient.get("/v1/faq");
  return response.data.data.data;
};

export function useFaqs() {
  return useQuery({
    queryKey: ["useFaq"],
    queryFn: getFaqFn,
  });
}
