import { shallow } from "zustand/shallow";
import categoryStore from "../store/category_store";

const categoryFacade = () => {
  const { categories, fetchCategories } = categoryStore(
    (state) => ({
      categories: state.categories,
      fetchCategories: state.fetchCategories,
    }),
    shallow
  );

  return { categories, fetchCategories };
};

export default categoryFacade;
