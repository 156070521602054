
class Toast {
    static show(message: string, { duration = 3000 }) {
        const toastContainer = document.createElement('div');
        toastContainer.className = 'toast-container';

        const toast = document.createElement('div');
        toast.className = 'toast';
        toast.innerText = message;

        toastContainer.appendChild(toast);
        document.body.appendChild(toastContainer);
        setTimeout(() => {
            toastContainer.classList.add('active')
        }, 100);
        setTimeout(() => {
            toastContainer.classList.remove('active')
        }, duration + 100);
        setTimeout(() => {
            document.body.removeChild(toastContainer);
        }, duration + 500);
    }
}

export default Toast