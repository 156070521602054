import { ReactNode, useEffect, useState } from "react";
import BottomSheet from "../../../../shared/components/bottom_sheet";

interface EditFormProp {
    children: ReactNode,
    open?: boolean;
    onClose?: () => void;
    onSubmit?: () => void;
}

function EditFormDialog(props: EditFormProp) {
    return (
        <BottomSheet open={props.open} onClose={props.onClose} title="Ubah Data">
            <div className="edit-form">
                <div className="height-25"></div>
                {props.children}
                <div className="height-25"></div>
            </div>
            <div className="row">
                <div className="expanded">
                    <div className="primary-button" onClick={props.onSubmit}>
                        Simpan Perubahan
                    </div>
                </div>
                <div className="width-10"></div>
                <div className="expanded">
                    <div className="secondary-button" onClick={props.onClose}>
                        Batal
                    </div>
                </div>
            </div>
        </BottomSheet>
    )
}

export default EditFormDialog