import { shallow } from "zustand/shallow";
import carouselStore from "../store/carousel_store";

const carouselFacade = () => {
  const { carousels, fetchCarousel } = carouselStore(
    (state) => ({
      carousels: state.carousels,
      fetchCarousel: state.fetchCarousel,
    }),
    shallow
  );

  return { carousels, fetchCarousel };
};

export default carouselFacade;
