import BottomSheet from "../../shared/components/bottom_sheet";
import voucherBanner from "../../../assets/images/voucher-banner.png";
import { useEffect, useState } from "react";
import Radio from "../../shared/components/radio";
import { CourierModel } from "../models/courier_model";
import CourierFacade from "../facades/courier_facade";

interface ShippingOptionsSeetProps {
  open?: boolean;
  onClose?: (val: boolean) => void;
  onSelected?: (courier: CourierModel) => void;
  onSubmitButton?: () => void;
}

function assetLoc(code?: string) {
  try {
    return require(`../../../assets/images/${code}.png`);
  } catch (error) {
    return require(`../../../assets/images/jne.png`);
  }
}

function ShippingOptions(props: ShippingOptionsSeetProps) {
  const [selectedCourier, setSelectedCourier] = useState<CourierModel>();
  const { couriers, fetchCouriers } = CourierFacade();

  useEffect(() => {
    if (couriers.length == 0) {
      fetchCouriers();
    }
  }, []);

  const handleSelected = (courier: CourierModel) => {
    setSelectedCourier(courier);
    props.onSelected?.(courier);
  };

  return (
    <BottomSheet
      isScroll={true}
      open={props?.open ?? false}
      onClose={() => props?.onClose?.(false)}
      title="Opsi Pengiriman"
    >
      <div className="shipping-options">
        {couriers.map((courier, index) => (
          <div
            className="options"
            key={courier.id}
            onClick={() => handleSelected(courier)}
          >
            <figure>
              <img src={courier.courier_image} alt={courier.name} />
            </figure>
            <div className="column expanded">
              <h5>
                {courier.name}
                <span>{courier.service_name}</span>
              </h5>
              <p>
                {courier.shipment_duration_range}{" "}
                {courier.shipment_duration_unit}
              </p>
            </div>
            <Radio checked={courier.id == selectedCourier?.id} />
          </div>
        ))}
      </div>
      <div className="primary-button" onClick={props.onSubmitButton}>
        Simpan
      </div>
    </BottomSheet>
  );
}

export default ShippingOptions;
