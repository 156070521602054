import { useNavigate, useLocation } from "react-router-dom";
import settingFacade from "../../order/facades/setting_facade";
import { useEffect } from "react";

interface TncPPProps {
    title?: string;
}
function TncPP(props: TncPPProps) {
    const navigate = useNavigate()
    const location = useLocation()

    const { setting, fetchSetting } = settingFacade();
    useEffect(
        () => {
            if (setting.privacy_policy == null) {
                fetchSetting()
            }
        }, []
    )

    return (
        <div id="base-root" className="tncpp">
            <div className="header">
                <svg onClick={() => navigate(-1)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 8.5L6 12.5M6 12.5L10 16.5M6 12.5L18 12.5" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="expanded">
                    {props.title ?? "Syarat dan Ketentuan"}
                </p>
            </div>
            <div className="contents" dangerouslySetInnerHTML={{ __html: location.state ?? ((props.title ?? "Syarat dan Ketentuan").toLowerCase().includes('kebijakan') ? setting.privacy_policy : setting.terms_conditions) }}>
            </div>
        </div>
    );
}

export default TncPP;