
import { shallow } from 'zustand/shallow'
import profileStore from '../store/profile_store'

const profileFacade = () => {
    const { user, uploadProgress, uploadImage, fetchUser, updateUser } = profileStore(
        (state) => ({
            user: state.user,
            uploadProgress: state.uploadProgress,
            fetchUser: state.fetchUser,
            uploadImage: state.uploadImage,
            updateUser: state.updateUser,
        }),
        shallow
    )

    return { user, fetchUser, updateUser, uploadProgress, uploadImage }
}

export default profileFacade