import { create } from 'zustand'
import { createJSONStorage, persist } from "zustand/middleware"
import Network from '../../../utils/network_utils';
import Api from '../../../endpoint/api';
import { AddressModel } from '../models/address_model';
import Toast from '../../../utils/toast';


interface AddressForm {
    receiver_name?: string;
    phone?: string;
    phone_country_code?: string;
    label?: string;
    latitude?: number;
    longitude?: number;
    province?: string;
    city?: string;
    sub_district?: string;
    full_address?: string;
    note?: string;
    is_main?: boolean;
}

type AddressStore = {
    address: AddressForm;
    addresses: AddressModel[];
    editableAddress: AddressForm;
    fetchAddress: () => void;
    resetForm: () => void;
    deleteAddress: (id: number) => Promise<boolean>;
    createAddress: (address: AddressForm) => Promise<boolean>;
    updateAddress: (address: AddressForm, id: number) => Promise<boolean>;
    setAddressField: (field: keyof AddressForm, value: string) => void
    setEditAddressField: (field: keyof AddressForm, value: string) => void
}

const addressStore = create<AddressStore>()(
    persist((set, get) => ({
        address: {
            phone_country_code: "+62"
        },
        addresses: [],
        editableAddress: {},
        resetForm: () => {
            set({
                address: {
                    phone_country_code: "+62" 
                }
            })
        },
        setAddressField: (field: keyof AddressForm, value: string) =>
            set((state) => ({ address: { ...state.address, [field]: value } })),
        setEditAddressField: (field: keyof AddressForm, value: string) =>
            set((state) => ({ editableAddress: { ...state.editableAddress, [field]: value } })),
        fetchAddress: async () => {
            const res = await Network.get(Api.address, { useToken: true })
            if (res?.ok ?? false) {
                const data = await res!.json()
                set({ addresses: [...data['data']] })
            }
        },
        createAddress: async (address) => {
            const res = await Network.post(Api.address, { data: { "phone_country_code": "+62", ...address }, useToken: true })
            if (res?.ok ?? false) {
                set((state) => {
                    state.fetchAddress()
                    return { address: {} }
                })
                return true;
            }
            return false;
        },
        updateAddress: async (address, id) => {
            const res = await Network.post(`${Api.address}/${id}`, { data: { "_method": "PUT", ...address }, useToken: true })
            if (res?.ok ?? false) {
                set((state) => {
                    state.fetchAddress()
                    return { address: {} }
                })
                return true;
            }
            return false;
        },
        deleteAddress: async (id) => {
            const res = await Network.post(`${Api.address}/${id}`, { data: { "_method": 'DELETE' }, useToken: true })
            if (res.ok) {
                const data = await res.json()
                Toast.show(data["message"] ?? "", {})
                set((state) => {
                    state.fetchAddress()
                    return {}
                })
                return true;
            }
            return false;
        },
    }),
        {
            name: "address-storage",
            storage: createJSONStorage(() => sessionStorage)
        }
    ),
)

export default addressStore