import moment from "moment";

export default function Testimoni(props) {
  return (
    <div className="detail-testimoni">
      <div className="top">
        <img src={props.testimonial.image_url_text} alt="" />
        <div className="text">
          <h1>{props.testimonial.customer_name}</h1>
          <h3>{props.testimonial.username}</h3>
        </div>
      </div>

      <div className="desc">
        <h2>{moment(props.testimonial.created_at).format("DD/MM/YYYY")}</h2>
        <p>{props.testimonial.testimonial}</p>
      </div>

      <div className="bottom">
        <img src={props.testimonial.svg_path_text} alt="" className="logo" />
        <button
          onClick={() =>
            window.open(props.testimonial.attachment_link, "_blank")
          }
        >
          Original Post
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 12L4 12"
              stroke="#DE8D8D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 7L19.2929 11.2929C19.6262 11.6262 19.7929 11.7929 19.7929 12C19.7929 12.2071 19.6262 12.3738 19.2929 12.7071L15 17"
              stroke="#DE8D8D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
