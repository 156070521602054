import { useEffect, useState, MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import banner from "../../../../../assets/images/mama-poin.svg";
import emptyTransaction from "../../../../../assets/images/empty_transaction.svg";
import EmptyState from "../../../components/empty_state";
import mamaPointFacade from "../../../facades/mama_point_facade";
import moment from "moment";
import "moment/locale/id";

interface PointModel {
  status?: string;
  amount?: string;
  type?: string;
}

function MamaPointPage() {
  const navigate = useNavigate();
  const { mamaPoint, fetchMamaPoint } = mamaPointFacade();
  const [dateFilter, setDateFilter] = useState("");

  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      currencyDisplay: "symbol", // Use 'code' for currency code (e.g., IDR)
    }).format(value);
  };

  useEffect(() => {
    fetchMamaPoint();
  }, []);

  return (
    <div className="mama-point">
      <div className="header">
        <svg
          onClick={() => navigate(-1)}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M10 8.5L6 12.5M6 12.5L10 16.5M6 12.5L18 12.5"
            stroke="#B87474"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Mama Poin
      </div>
      <div className="banner">
        <figure>
          <img src={banner} />
          <span>
            {numberWithCommas(
              (mamaPoint.data?.length ?? 0) == 0
                ? 0
                : mamaPoint.data![0].point_final ?? 0
            )}
          </span>
        </figure>
      </div>
      <div className="content">
        <div className="filter-section">
          <div className="expanded">
            <h5>Riwayat Mama Poin</h5>
            <p>Lihat semua transaksi Mama Poin anda.</p>
          </div>
          <div className="expanded">
            <div className="date-picker">
              <input
                type="date"
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="divider"></div>
        {(mamaPoint.data?.length ?? 0) == 0 ? (
          <EmptyState
            icon={emptyTransaction}
            title="Tidak Ada Transaksi"
            subtitle="Belum ada riwayat mama poin"
          />
        ) : (
          mamaPoint.data?.map((point, index) => (
            <div key={index} className="point-item">
              <div className="expanded">
                <div className="title">
                  {(point.point ?? 0) >= 0 ? "Pemasukan" : "Pembayaran"}
                </div>
                <div className="subtitle">
                  {moment
                    .utc(point.created_at)
                    .utcOffset("+07:00")
                    .format("DD MMMM YYYY, HH:mm")}{" "}
                </div>
              </div>
              <div className="points">
                <span className="status">{point.status?.text}</span>
                <span>{point.point ?? 0}</span>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default MamaPointPage;
