import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import GoogleLoginButton from "../components/google_login_button";
import TextInput from "../../shared/components/text_input";
import { useEffect, useState } from "react";
import authFacade from "../facades/auth_facade";
import homeFacade from "../../order/facades/home_facade";
import { getFcmToken } from "../../../firebase";

function LoginScreen() {
  const navigate = useNavigate();
  const [activeTab, setactiveTab] = useState(0);
  const { activeMenu, setActiveMenu } = homeFacade();
  const { loginForm, setField, login, updateFcmToken } = authFacade();
  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    setField(name as keyof typeof loginForm, value);
  };

  const handleSubmit = () => {
    login(loginForm, activeTab == 0 ? "email" : "phone").then(
      async (status) => {
        if (status.status) {
          if (status.isVerifed) {
            const checkToken = await getFcmToken();
            updateFcmToken(checkToken).then((status) => {
              if (status) {
                setActiveMenu("beranda");
                navigate("/store/home");
              }
            });
          } else {
            navigate("/store/otp", {
              state: { type: activeTab == 0 ? "email" : "phone" },
            });
          }
        }
      }
    );
  };

  return (
    <div id="auth-root" className="login">
      <div className="header">
        <img src={logo} alt="" />
        <h1>Masuk Ke Akun</h1>
      </div>
      <div className="tab">
        <button
          className={activeTab == 0 ? "active" : ""}
          onClick={function () {
            setactiveTab(0);
          }}
        >
          Email
        </button>
        <button
          className={activeTab == 1 ? "active" : ""}
          onClick={function () {
            setactiveTab(1);
          }}
        >
          Nomor Handphone
        </button>
      </div>
      {activeTab == 0 ? (
        <>
          <TextInput
            isObsecured={false}
            label={"Email"}
            name={"email"}
            value={loginForm.email}
            onChange={handleInputChange}
            placeholder={"Masukkan email"}
            text={"Contoh : john@email.com"}
          />
          <div className="height-20"></div>
          <TextInput
            isObsecured={true}
            label={"Kata Sandi"}
            name={"password"}
            value={loginForm.password}
            onChange={handleInputChange}
            placeholder={"Masukkan kata sandi"}
          />
        </>
      ) : (
        <>
          <TextInput
            isObsecured={false}
            label={"Nomor Handphone"}
            name={"phone"}
            value={loginForm.phone}
            onChange={handleInputChange}
            placeholder={"Masukkan nomor handpone"}
            text="Contoh : 8123456789"
          />
          <div className="height-20"></div>
          <TextInput
            isObsecured={true}
            label={"Kata Sandi"}
            name={"password"}
            value={loginForm.password}
            onChange={handleInputChange}
            placeholder={"Masukkan kata sandi"}
          />
        </>
      )}
      <div className="height-20"></div>
      <div className="height-15"></div>
      <button type="button" onClick={handleSubmit} className="primary-button">
        Masuk
      </button>
      <div className="divider">
        <span></span>
        Atau masuk dengan
        <span></span>
      </div>
      <GoogleLoginButton />
      <div className="height-13"></div>
      <p className="actions">
        Belum punya akun?
        <Link to="/store/register">
          <span>Daftar</span>
        </Link>
      </p>
    </div>
  );
}
export default LoginScreen;
