import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { apiClient } from "../../../../api/api-client";
import Navbar from "../../components/navbar";
import TitleHeader from "../../components/title-header";
import Footer from "../../components/footer";

export default function ContactUs() {
  const toast = useToast();

  const [formData, setFormData] = useState({
    destination: "customer_care", // Set default value
    customer_name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await apiClient.post("/v1/contact-messages", formData);
      if (response.status === 200) {
        toast({
          title: "Form submitted successfully",
          status: "success",
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "Error submitting form:",
        status: "error",
        position: "top-right",
        description: JSON.stringify(error?.response?.data?.message ?? "-"),
      });
    }
  };

  return (
    <>
      <div className="wrapper-pages">
        <Navbar />
        <TitleHeader
          title={"Hubungi Kami"}
          bread={
            <div
              dangerouslySetInnerHTML={{
                __html: "Bantuan / <span>Kontak Kami</span>",
              }}
            />
          }
        />

        <div className="container">
          <div className="contact-us">
            <div className="hero">
              <div className="left">
                <p style={{ marginBottom: "0" }}>BANTUAN</p>
                <h1>Tim MamaBear siap membantu Anda!</h1>
                <p>
                  Silahkan isi formulir dibawah ini dan kami akan segera
                  membantu.
                </p>
              </div>

              <div className="right">
                <img src="images/contact-us-hero.png" alt="" />
              </div>
            </div>
            <form className="form" onSubmit={handleSubmit}>
              <div>
                <div className="item">
                  <label htmlFor="destination">Tujuan</label>
                  <select
                    name="destination"
                    value={formData.destination}
                    onChange={handleChange}
                  >
                    <option value="customer_care">Customer Care</option>
                    <option value="business_inquiry">Business Inquiry</option>
                  </select>
                </div>
                <div className="item">
                  <label htmlFor="customer_name">Nama</label>
                  <input
                    required
                    name="customer_name"
                    type="text"
                    placeholder="Masukkan nama anda"
                    value={formData.customer_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="item">
                  <label htmlFor="email">Email</label>
                  <input
                    required
                    name="email"
                    type="email"
                    placeholder="Masukkan alamat email anda"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="item">
                  <label htmlFor="subject">Subyek</label>
                  <input
                    required
                    name="subject"
                    type="text"
                    placeholder="Masukkan subyek"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                </div>
                <div className="item">
                  <label htmlFor="message">Pesan</label>
                  <textarea
                    required
                    name="message"
                    rows={5}
                    type="text"
                    placeholder="Sampaikan kebutuhan anda"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <button className="button-custom" type="submit">
                  Kirim
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.375 7.72266L3 7.72266"
                      stroke="white"
                      strokeWidth="0.9375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.875 4.59766L12.5581 7.28071C12.7664 7.48905 12.8706 7.59321 12.8706 7.72266C12.8706 7.8521 12.7664 7.95626 12.5581 8.1646L9.875 10.8477"
                      stroke="white"
                      strokeWidth="0.9375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
