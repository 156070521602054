import { ReactNode, useEffect, useState } from "react";
import BottomSheet from "../../../shared/components/bottom_sheet";
import icon from "../../../../assets/images/cart_ilustration.svg"

interface ConfirmationProp {
    open?: boolean;
    icon?: string;
    label?: string;
    text?: string;
    actionText?: string;
    onClose?: () => void;
    onSubmit?: () => void;
}

function ConfirmationDialog(props: ConfirmationProp) {
    return (
        <BottomSheet open={props.open} onClose={props.onClose} title="Konfirmasi">
            <div className="empty-state">
                <img src={props.icon ?? icon} />
                <h5>
                    {props.label}
                </h5>
                <p dangerouslySetInnerHTML={{ __html: props.text ?? '' }}>
                </p>
            </div>
            <div className="height-25"></div>
            <div className="row">
                <div className="primary-button expanded" onClick={() => {
                    props.onSubmit?.();
                    props.onClose?.();
                }}>
                    {props.actionText ?? 'Ya, Saya yakin'}
                </div>
                <div className="width-10"></div>
                <div className="secondary-button expanded" onClick={props.onClose}>
                    Batal
                </div>
            </div>
        </BottomSheet>
    )
}

export default ConfirmationDialog