import { useState } from "react";
import {
    Link
} from "react-router-dom";
function Agreement() {
    const [isAgree, setAgreement] = useState(false);

    return (
        <div className="agreement">
            <div className={`check-box${isAgree ? ' checked' : ''}`} onClick={function () {
                setAgreement(!isAgree)
            }}>
                {
                    isAgree ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.00001 12.3028C9.92879 12.3028 12.303 9.92859 12.303 6.99981C12.303 4.07102 9.92879 1.69678 7.00001 1.69678C4.07122 1.69678 1.69698 4.07102 1.69698 6.99981C1.69698 9.92859 4.07122 12.3028 7.00001 12.3028ZM9.43517 5.6531C9.57003 5.47971 9.53879 5.22983 9.3654 5.09497C9.19201 4.96011 8.94213 4.99135 8.80727 5.16474L6.68235 7.89678C6.6351 7.95753 6.54622 7.96542 6.48901 7.91393L5.14486 6.7042C4.98159 6.55725 4.73011 6.57049 4.58317 6.73376C4.43622 6.89703 4.44946 7.14851 4.61273 7.29545L5.95688 8.50519C6.35733 8.86559 6.97949 8.8104 7.31024 8.38514L9.43517 5.6531Z" fill="white" />
                        </svg> : null
                }
            </div>
            Saya menyetujui
            <span>
                <Link to="/store/terms-condition">
                    Syarat Ketentuan
                </Link>
            </span>
            &
            <span>
                <Link to="/store/privacy-policy">
                    Kebijakan Privasi
                </Link>
            </span>
        </div>
    );
}

export default Agreement;