import { MouseEventHandler } from "react";

interface OptionsProps {
    isActive?: boolean;
    icon?: string;
    title?: string;
    subtitle?: string;
    onTap: MouseEventHandler;
}
function VerificationOptions(props: OptionsProps) {
    return (
        <div onClick={props.onTap} className={"verification-options" + (props.isActive ?? false ? " is-active" : "")}>
            <div className="row">
                <figure>
                    <img src={props.icon} alt="" />
                </figure>
                <div className="expanded">
                    <div className="column crossAxisStart mainAxisCenter">
                        <h5>
                            {props.title}
                        </h5>
                        <p>
                            {props.subtitle}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerificationOptions;