import { useState, MouseEventHandler, ChangeEvent } from "react";
import BottomSheet from "../../../../shared/components/bottom_sheet";
import menuUser from '../../../../../assets/images/menu_user.svg';
import menuEmail from '../../../../../assets/images/menu_email.svg';
import menuGender from '../../../../../assets/images/menu_gender.svg';
import menuDate from '../../../../../assets/images/menu_date.svg';
import menuPhone from '../../../../../assets/images/menu_phone.svg';
import profileImage from '../../../../../assets/images/profile.png';
import TextInput from "../../../../shared/components/text_input";
import profileFacade from "../../../facades/profile_facade";

interface EditPasswordDialogProps {
    open?: boolean;
    onClose?: () => void;
}


function EditPasswordDialog(props: EditPasswordDialogProps) {
    const { updateUser } = profileFacade();
    const [formData, setFormData] = useState({
        password: '',
        password_old: '',
        password_confirmation: '',
    });

    const [errors, setErrors] = useState({
        password: '',
        password_old: '',
        password_confirmation: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = () => {

        // Validation rules
        const newErrors = {
            password: formData.password.length < 8 ? 'Please enter at least 8 character' : '',
            password_old: formData.password_old.length < 8 ? 'Please enter at least 8 character' : '',
            password_confirmation: formData.password_confirmation.length < 8 ? 'Please enter at least 8 character' : formData.password == formData.password_confirmation ? '' : 'Password does not match',
        };

        setErrors({ ...newErrors });

        // Check if there are any validation errors
        if (!Object.values(newErrors).some((error) => error !== '')) {
            updateUser(formData).then((success) => {
                if (success) {
                    setFormData(
                        {
                            password: '',
                            password_old: '',
                            password_confirmation: '',
                        }
                    )
                    setErrors(
                        {
                            password: '',
                            password_old: '',
                            password_confirmation: '',
                        }
                    )
                    props.onClose?.();
                }
            });
        }
    };

    return (
        <BottomSheet open={props.open} onClose={props.onClose} title="Ganti Kata Sandi">
            <div className="profile-form">
                <TextInput isPrimary={false} prefix={
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.83335 4.49967C5.83335 3.30306 6.8034 2.33301 8.00002 2.33301C9.19664 2.33301 10.1667 3.30306 10.1667 4.49967V5.33301H5.83335V4.49967ZM4.83335 5.37981V4.49967C4.83335 2.75077 6.25112 1.33301 8.00002 1.33301C9.74892 1.33301 11.1667 2.75077 11.1667 4.49967V5.37981C12.4004 5.61384 13.3334 6.69781 13.3334 7.99967V11.9997C13.3334 13.4724 12.1394 14.6663 10.6667 14.6663H5.33335C3.86059 14.6663 2.66669 13.4724 2.66669 11.9997V7.99967C2.66669 6.69781 3.59959 5.61384 4.83335 5.37981ZM8.00002 8.83301C8.27616 8.83301 8.50002 9.05687 8.50002 9.33301V10.6663C8.50002 10.9425 8.27616 11.1663 8.00002 11.1663C7.72388 11.1663 7.50002 10.9425 7.50002 10.6663V9.33301C7.50002 9.05687 7.72388 8.83301 8.00002 8.83301Z" fill="#DE8D8D" />
                    </svg>
                }
                    isOutlined={true}
                    isObsecured={true}
                    label="Kata Sandi Lama"
                    name="password_old"
                    onChange={handleChange}
                    value={formData.password_old}
                    text={errors.password_old} />
                <TextInput isPrimary={false} prefix={
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.83335 4.49967C5.83335 3.30306 6.8034 2.33301 8.00002 2.33301C9.19664 2.33301 10.1667 3.30306 10.1667 4.49967V5.33301H5.83335V4.49967ZM4.83335 5.37981V4.49967C4.83335 2.75077 6.25112 1.33301 8.00002 1.33301C9.74892 1.33301 11.1667 2.75077 11.1667 4.49967V5.37981C12.4004 5.61384 13.3334 6.69781 13.3334 7.99967V11.9997C13.3334 13.4724 12.1394 14.6663 10.6667 14.6663H5.33335C3.86059 14.6663 2.66669 13.4724 2.66669 11.9997V7.99967C2.66669 6.69781 3.59959 5.61384 4.83335 5.37981ZM8.00002 8.83301C8.27616 8.83301 8.50002 9.05687 8.50002 9.33301V10.6663C8.50002 10.9425 8.27616 11.1663 8.00002 11.1663C7.72388 11.1663 7.50002 10.9425 7.50002 10.6663V9.33301C7.50002 9.05687 7.72388 8.83301 8.00002 8.83301Z" fill="#DE8D8D" />
                    </svg>
                }
                    isOutlined={true}
                    isObsecured={true}
                    label="Kata Sandi Baru"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    text={errors.password} />
                <TextInput isPrimary={false} prefix={
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.83335 4.49967C5.83335 3.30306 6.8034 2.33301 8.00002 2.33301C9.19664 2.33301 10.1667 3.30306 10.1667 4.49967V5.33301H5.83335V4.49967ZM4.83335 5.37981V4.49967C4.83335 2.75077 6.25112 1.33301 8.00002 1.33301C9.74892 1.33301 11.1667 2.75077 11.1667 4.49967V5.37981C12.4004 5.61384 13.3334 6.69781 13.3334 7.99967V11.9997C13.3334 13.4724 12.1394 14.6663 10.6667 14.6663H5.33335C3.86059 14.6663 2.66669 13.4724 2.66669 11.9997V7.99967C2.66669 6.69781 3.59959 5.61384 4.83335 5.37981ZM8.00002 8.83301C8.27616 8.83301 8.50002 9.05687 8.50002 9.33301V10.6663C8.50002 10.9425 8.27616 11.1663 8.00002 11.1663C7.72388 11.1663 7.50002 10.9425 7.50002 10.6663V9.33301C7.50002 9.05687 7.72388 8.83301 8.00002 8.83301Z" fill="#DE8D8D" />
                    </svg>
                }
                    isOutlined={true}
                    isObsecured={true}
                    label="Konfirmasi Kata Sandi Baru"
                    name="password_confirmation"
                    onChange={handleChange}
                    value={formData.password_confirmation}
                    text={errors.password_confirmation} />
            </div>
            <div className="row">
                <div className="expanded">
                    <div className="primary-button" onClick={handleSubmit}>
                        Simpan
                    </div>
                </div>
                <div className="width-10"></div>
                <div className="expanded">
                    <div className="secondary-button" onClick={props.onClose}>
                        Batal
                    </div>
                </div>
            </div>
        </BottomSheet>
    )
}

export default EditPasswordDialog