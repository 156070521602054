import { useState, MouseEventHandler, ChangeEvent } from "react";
import BottomSheet from "../../../../shared/components/bottom_sheet";
import logout from '../../../../../assets/images/logout.svg';


interface LogoutDialogProps {
    open?: boolean;
    onClose?: () => void;
    onSubmit?: () => void;
}

function LogoutDialog(props: LogoutDialogProps) {
    return (
        <BottomSheet open={props.open} onClose={props.onClose} title="Konfirmasi">
            <div className="logout-dialog">
                <img src={logout} alt="" />
                <h3>
                    Keluar Akun
                </h3>
                <p>
                    Apakah anda yakin ingin keluar<br />
                    dari akun anda sekarang?
                </p>
            </div>
            <div className="row">
                <div className="expanded">
                    <div className="primary-button" onClick={props.onSubmit}>
                        Ya, Keluar
                    </div>
                </div>
                <div className="width-10"></div>
                <div className="expanded">
                    <div className="secondary-button" onClick={props.onClose}>
                        Batal
                    </div>
                </div>
            </div>
        </BottomSheet>
    )
}

export default LogoutDialog