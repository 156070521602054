
import { shallow } from 'zustand/shallow'
import addressStore from '../store/address_store'

const addressFacade = () => {
    const { address, editableAddress, addresses, fetchAddress, deleteAddress, createAddress, updateAddress, setAddressField, setEditAddressField, resetForm } = addressStore(
        (state) => ({
            address: state.address,
            addresses: state.addresses,
            editableAddress: state.editableAddress,
            resetForm: state.resetForm,
            fetchAddress: state.fetchAddress,
            deleteAddress: state.deleteAddress,
            createAddress: state.createAddress,
            updateAddress: state.updateAddress,
            setAddressField: state.setAddressField,
            setEditAddressField: state.setEditAddressField,
        }),
        shallow
    )

    return { address, editableAddress, addresses, fetchAddress, deleteAddress, createAddress, updateAddress, setAddressField, setEditAddressField, resetForm }
}

export default addressFacade