
import { shallow } from 'zustand/shallow'
import homeStore from '../store/home_store'

const homeFacade = () => {
    const { activeMenu, setActiveMenu } = homeStore(
        (state) => ({
            activeMenu: state.activeMenu,
            setActiveMenu: state.setActiveMenu,
        }),
        shallow
    )

    return { activeMenu, setActiveMenu }
}

export default homeFacade