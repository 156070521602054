import EmptyState from "../../../components/empty_state";
import emptyIcon from "../../../../../assets/images/empty_address.svg";
import { useState, useEffect } from "react";
import AddressMoreOptionDialog from "../dialogs/address_more_option_dialog";
import { useNavigate } from "react-router-dom";
import DeleteAddressDialog from "../dialogs/delete_address_dialog";
import back from "../../../../../assets/images/ic_arrow-left.svg";
import routes from "../../../../../routes";
import { AddressModel } from "../../../models/address_model";
import addressFacade from "../../../facades/address_facade";
import Toast from "../../../../../utils/toast";

function AddressPage() {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressModel>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { addresses, fetchAddress, deleteAddress, resetForm } = addressFacade();

  useEffect(() => {
    if (addresses.length == 0) {
      fetchAddress();
    }
  }, []);

  return (
    <div className="base-page">
      <div className="header p25">
        <img
          src={back}
          alt=""
          className="back-btn"
          onClick={() => navigate(-1)}
        />

        <p className="title">Kelola Alamat</p>
      </div>
      <div className="content">
        {addresses.length == 0 ? (
          <EmptyState
            icon={emptyIcon}
            title="Alamat Kosong"
            subtitle="Belum ada alamat yang di daftarkan.<br/>Tambahkan alamat anda"
          >
            <div
              className="primary-button"
              style={{ width: 200, marginTop: 10, alignSelf: "center" }}
              onClick={() => navigate(routes.addAddress)}
            >
              Tambah Alamat
            </div>
          </EmptyState>
        ) : (
          <>
            {addresses.map((value, index) => (
              <AddressCard
                address={value}
                key={index}
                onClick={() => {
                  setShowMore(true);
                  setSelectedAddress(value);
                }}
              />
            ))}
            <div
              className="primary-button"
              onClick={() => {
                resetForm();
                navigate(routes.addAddress);
              }}
            >
              Tambah Alamat
            </div>
          </>
        )}
      </div>
      <AddressMoreOptionDialog
        onDelete={() => setShowDeleteConfirmation(true)}
        open={showMore}
        onClose={() => setShowMore(false)}
        onEdit={() => navigate(routes.addAddress, { state: selectedAddress })}
      />
      <DeleteAddressDialog
        open={showDeleteConfirmation}
        onClose={() => {
          setShowDeleteConfirmation(false);
          setShowMore(false);
        }}
        onDelete={() => deleteAddress(selectedAddress?.id ?? 0)}
      />
    </div>
  );
}

interface AddressProps {
  address: AddressModel;
  onClick?: () => void;
}
function AddressCard(props: AddressProps) {
  return (
    <div
      className={`address-card${
        props.address.latitude != null ? " active" : ""
      }`}
    >
      <div className="address-header">
        <div className="label">
          {`${props.address.label}${
            props.address.is_main ?? false ? " (utama)" : ""
          }`}
        </div>
        <div className="more-action" onClick={props.onClick}></div>
      </div>
      <div className="address">
        {`${props.address.full_address} ${props.address.sub_district} ${props.address.city}`}
      </div>
      <div className="divider"></div>
      <div className="row">
        <div className="recipient expanded">
          <div className="name">{props.address.receiver_name}</div>
          <div className="phone">{props.address.phone}</div>
        </div>
        <div
          className={`status${props.address.latitude != null ? " active" : ""}`}
        >
          {props.address.latitude != null
            ? "Sudah di pin lokasi"
            : "Lokasi belum di pin"}
        </div>
      </div>
    </div>
  );
}

export default AddressPage;
