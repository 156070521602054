import { shallow } from "zustand/shallow";
import voucherStore from "../store/voucher_store";

const voucherFacade = () => {
  const { vouchers, fetchVouchers } = voucherStore(
    (state) => ({
      vouchers: state.vouchers,
      fetchVouchers: state.fetchVouchers,
    }),
    shallow
  );

  return { vouchers, fetchVouchers };
};

export default voucherFacade;
