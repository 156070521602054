import { create } from "zustand";
import { CourierModel } from "../models/courier_model";
import { persist } from "zustand/middleware";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";

type CourierStore = {
    couriers: CourierModel[];
    fetchCouriers: () => void;
}

const courierStore = create<CourierStore>()(
    persist((set, get) => ({
        couriers: [],
        fetchCouriers: async () => {
            const res = await Network.get(Api.couriers, {});
            if (res?.ok ?? false) {
                const body = await res!.json()
                const couriers = body['data']
                set({ couriers: couriers })
            }
        }
    }), {
        name: "courier-storage",
        getStorage: () => sessionStorage
    })
)

export default courierStore;