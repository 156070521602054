import { create } from "zustand";
import { CourierFee } from "../models/courier_fee";
import { persist } from "zustand/middleware";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";
import { Order, OrderModel, OrderPagination } from "../models/order_model";
import Toast from "../../../utils/toast";

interface OrderPayload {
  cartIds?: number[];
  courierId?: number;
  userAddressId?: number;
  paymentMethodId?: number;
  userVoucherId?: number;
  dropShipperName?: string;
  dropShipperPhone?: string;
  mamaPoint?: boolean;
}

interface guestOrderPayload {
  courier_id?: number;
  dropshipper_name?: string;
  dropdhipper_phone?: string;
  payment_method_id?: number;
  address: guestAddress;
  carts?: guestCart[];
}

interface GuestestimateOrderFeePayload {
  courier_ids: number[];
  address: guestAddress;
  carts: guestCart[];
}

interface guestAddress {
  fullName?: string;
  full_name?: string;
  phone_country_code?: string;
  phone?: string;
  city?: string;
  full_address?: string;
  latitude?: string;
  longitude?: string;
}

interface guestCart {
  product_id?: number;
  qty?: number;
}

interface ListOrderPayload {
  status?: string;
  start_date?: string;
  end_date?: string;
  search_key?: string;
}

interface OrderCancel {
  order_id?: number;
  note?: string;
  reason?: string;
}

interface complainPayload {
  order_id: number;
  complain_type_id: number;
  whatsapp: string;
  description?: string;
  order_product?: complainOrderProduct[];
  document?: complainDocument[];
}

interface complainOrderProduct {
  id: number;
  qty: number;
}

interface complainDocument {
  file?: File | null;
  description?: string;
}

type OrderStore = {
  orders: OrderPagination;
  order: OrderModel;
  courierFee: CourierFee;
  tracking: Order;

  fetchOrders: ({
    status,
    start_date,
    end_date,
    search_key,
  }: ListOrderPayload) => void;

  fetchOrderDetail: (id: number) => Promise<boolean>;

  storeOrder: ({
    cartIds,
    courierId,
    userAddressId,
    paymentMethodId,
    userVoucherId,
    dropShipperName,
    dropShipperPhone,
    mamaPoint,
  }: OrderPayload) => Promise<boolean>;

  fetchCourierFee: (
    courierIds: number[],
    cartIds: number[],
    user_address_id: number
  ) => void;

  storeOrderGuest: ({
    courier_id,
    dropshipper_name,
    dropdhipper_phone,
    payment_method_id,
    address,
    carts,
  }: guestOrderPayload) => Promise<boolean>;

  fetchCourierFeeGuest: ({
    courier_ids,
    address,
    carts,
  }: GuestestimateOrderFeePayload) => void;

  cancelOrder: (payload: OrderCancel) => Promise<boolean>;

  cancelOrderCancel: (id: number) => Promise<boolean>;

  acceptOrder: (id: number) => Promise<boolean>;

  trackingOrder: (id: number) => void;

  complainOrder: (payload: complainPayload) => Promise<boolean>;
};

const orderStore = create<OrderStore>()(
  persist(
    (set, get) => ({
      orders: {},
      order: {},
      courierFee: {},
      tracking: {},
      fetchOrders: async ({
        status,
        start_date,
        end_date,
        search_key,
      }: ListOrderPayload) => {
        const res = await Network.get(Api.listOrder, {
          queryParameters: {
            status: status,
            start_date: start_date,
            end_date: end_date,
            search_key: search_key,
          },
        });
        if (res?.ok ?? false) {
          const body = await res!.json();
          const orders = body["data"];
          set({ orders: orders });
        }
      },
      fetchOrderDetail: async (id: number) => {
        let status = false;
        const res = await Network.get(
          Api.detailOrder.replace(":id", id.toString()),
          {}
        );
        if (res?.ok ?? false) {
          const body = await res!.json();
          const order = body;
          set({ order: order });
          status = true;
        }

        return status;
      },
      storeOrder: async ({
        cartIds,
        courierId,
        userAddressId,
        paymentMethodId,
        userVoucherId,
        dropShipperName,
        dropShipperPhone,
        mamaPoint,
      }: OrderPayload) => {
        const res = await Network.post(Api.order, {
          data: {
            cart_ids: cartIds,
            courier_id: courierId,
            user_address_id: userAddressId,
            payment_method_id: paymentMethodId,
            user_voucher_id: userVoucherId,
            dropshipper_name: dropShipperName,
            dropshipper_phone: dropShipperPhone,
            mama_point: mamaPoint ? 1 : 0,
          },
        });

        if (res?.ok ?? false) {
          const body = await res!.json();
          Toast.show(body["message"], {});
          await set({ order: body });
          return true;
        }

        return false;
      },
      fetchCourierFee: async (
        courierIds: number[],
        cartIds: number[],
        user_address_id: number
      ) => {
        const res = await Network.post(Api.orderFee, {
          data: {
            courier_ids: courierIds,
            cart_ids: cartIds,
            user_address_id: user_address_id,
          },
        });
        if (res?.ok ?? false) {
          const body = await res!.json();
          const courierFee = body["data"];
          set({ courierFee: courierFee });
        }
      },
      storeOrderGuest: async ({
        courier_id,
        dropshipper_name,
        dropdhipper_phone,
        payment_method_id,
        address,
        carts,
      }: guestOrderPayload) => {
        const formData = new FormData();
        formData.append("courier_id", courier_id?.toString() ?? "");
        formData.append("dropshipper_name", dropshipper_name ?? "");
        formData.append("dropshipper_phone", dropdhipper_phone ?? "");
        formData.append(
          "payment_method_id",
          payment_method_id?.toString() ?? ""
        );

        formData.append("address[full_name]", address.fullName ?? "");
        formData.append(
          "address[phone_country_code]",
          address.phone_country_code ?? ""
        );
        formData.append("address[phone]", address.phone ?? "");
        formData.append("address[city]", address.city ?? "");
        formData.append("address[full_address]", address.full_address ?? "");
        formData.append("address[latitude]", address.latitude ?? "");
        formData.append("address[longitude]", address.longitude ?? "");

        carts?.forEach((item, index) => {
          formData.append(
            `carts[${index}][product_id]`,
            (item.product_id ?? "").toString()
          );
          formData.append(`carts[${index}][qty]`, (item.qty ?? "").toString());
        });

        const res = await Network.post(Api.guestOrder, {
          data: formData,
        });

        if (res?.ok ?? false) {
          const body = await res!.json();
          Toast.show(body["message"], {});
          await set({ order: body });
          return true;
        }

        return false;
      },
      fetchCourierFeeGuest: async ({
        courier_ids,
        address,
        carts,
      }: GuestestimateOrderFeePayload) => {
        const requestAddress = address;
        requestAddress.full_name = address.fullName;

        const formData = new FormData();
        formData.append("courier_ids[]", courier_ids[0].toString());

        formData.append("address[full_name]", address.fullName ?? "");
        formData.append(
          "address[phone_country_code]",
          address.phone_country_code ?? ""
        );
        formData.append("address[phone]", address.phone ?? "");
        formData.append("address[city]", address.city ?? "");
        formData.append("address[full_address]", address.full_address ?? "");
        formData.append("address[latitude]", address.latitude ?? "");
        formData.append("address[longitude]", address.longitude ?? "");

        carts.forEach((item, index) => {
          formData.append(
            `carts[${index}][product_id]`,
            (item.product_id ?? "").toString()
          );
          formData.append(`carts[${index}][qty]`, (item.qty ?? "").toString());
        });

        const res = await Network.post(Api.guestOrderFee, {
          data: formData,
        });
        if (res?.ok ?? false) {
          const body = await res!.json();
          const courierFee = body["data"];
          set({ courierFee: courierFee });
        }
      },

      cancelOrder: async (payload: OrderCancel) => {
        payload.reason = "other";
        const res = await Network.post(Api.cancelOrder, {
          data: payload,
        });
        try {
          if (res?.ok ?? false) {
            const body = await res!.json();
            Toast.show(body["message"], {});
            await set({ order: body });
            return true;
          }
        } catch (error: any) {
          Toast.show(error, {});
          return false;
        }

        return false;
      },

      cancelOrderCancel: async (id: number) => {
        const res = await Network.post(Api.cancelOrderCancel, {
          data: { order_id: id },
        });

        try {
          if (res?.ok ?? false) {
            const body = await res!.json();
            Toast.show(body["message"], {});
            await set({ order: body });
            return true;
          }
        } catch (error: any) {
          Toast.show(error, {});
          return false;
        }

        return false;
      },

      acceptOrder: async (id: number) => {
        const res = await Network.post(Api.acceptOrder, {
          data: { order_id: id },
        });

        try {
          if (res?.ok ?? false) {
            const body = await res!.json();
            Toast.show(body["message"], {});
            return true;
          }
        } catch (error: any) {
          Toast.show(error, {});
          return false;
        }
        return false;
      },

      trackingOrder: async (id: number) => {
        const res = await Network.get(
          Api.tracking.replace(":id", id.toString()),
          {}
        );

        if (res?.ok ?? false) {
          const body = await res!.json();
          const tracking = body["data"]["order"];
          set({ tracking: tracking });
        }
      },

      complainOrder: async (payload: complainPayload) => {
        const formData = new FormData();

        formData.append("order_id", payload.order_id.toString());
        formData.append(
          "complain_type_id",
          payload.complain_type_id.toString()
        );
        formData.append("whatsapp", payload.whatsapp);
        formData.append("description", payload.description ?? "");

        payload?.order_product?.forEach((item, index) => {
          formData.append(`order_product[${index}][id]`, item.id.toString());
          formData.append(`order_product[${index}][qty]`, item.qty.toString());
        });

        payload?.document?.forEach((item, index) => {
          formData.append(
            `document[${index}][file]`,
            (item.file ?? null) as any
          );
          formData.append(
            `document[${index}][description]`,
            item.description ?? ""
          );
        });

        const res = await Network.post(Api.complain, {
          data: formData,
        });

        try {
          if (res?.ok ?? false) {
            const body = await res!.json();
            Toast.show("Berhasil Mengajukan Komplain", {});
            return true;
          }
        } catch (error: any) {
          Toast.show(error, {});
          return false;
        }

        return false;
      },
    }),
    {
      name: "order-storage",
      getStorage: () => sessionStorage,
    }
  )
);

export default orderStore;
