
import { shallow } from 'zustand/shallow'
import mamaPointStore from '../store/mama_point_store'

const mamaPointFacade = () => {
    const { mamaPoint, fetchMamaPoint } = mamaPointStore(
        (state) => ({
            mamaPoint: state.mamaPoint,
            fetchMamaPoint: state.fetchMamaPoint,
        }),
        shallow
    )

    return { mamaPoint, fetchMamaPoint }
}

export default mamaPointFacade