import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../../../api/api-client";

export const GetResi = async (noResi) => {
    try {
        const response = await apiClient.get(`/v1/landing-pages/tracking-resi/${noResi}`);
        return response.data.data; // Mengembalikan seluruh data dari response
    } catch (error) {
        throw new Error('Failed to fetch resi data'); // Melemparkan error jika panggilan API gagal
    }
};

