export interface ProductReviewPagination {
    current_page?: number
    data?: ProductReview[]
    first_page_url?: string
    from?: number
    last_page?: number
    last_page_url?: string
    links?: Link[]
    next_page_url?: any
    path?: string
    per_page?: number
    prev_page_url?: any
    to?: number
    total?: number
  }
  
  export interface ProductReview {
    id?: number
    order_product_id?: number
    rating?: number
    description?: string
    status?: string
    created_at?: string
    updated_at?: string
    deleted_at?: any
    order_product_review_images_count?: number
    user?: User
    order_product_review_images?: string[]
    created_at_text?: string
  }
  
  export interface User {
    id?: number
    role?: string
    name?: string
    email?: string
    is_male?: any
    phone_country_code?: string
    phone?: string
    sso_provider?: any
    image_url?: any
    birth_date?: string
    email_verified_at?: string
    phone_verified_at?: any
    created_at?: string
    updated_at?: string
    metadata?: Metadata
    image_url_text?: any
    membership_level?: string
  }
  
  export interface Metadata {
    transaction_amount_saved?: number
    transaction_amount_total?: number
  }
  
  export interface Link {
    url?: string
    label?: string
    active?: boolean
  }
  
  export const ReviewFilter = {
    hingest_rating: {
      text: "Rating Tertinggi",
      value: "highest_rating",
    },
    lowest_rating: {
      text: "Rating Terendah",
      value: "lowest_rating",
    },
    oldest: {
      text: "Terbaru",
      value: '',
    },
    newest: {
      text: "Terlama",
      value: "oldest",
    },
  }