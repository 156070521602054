import { useNavigate } from "react-router-dom";
import back from "../../../assets/images/ic_arrow-left.svg";
import CartItem from "../components/cart_item";
import CartSummary from "../components/cart_summary";
import EmptyCart from "../components/empty_state";
import ProductModel from "../../product/models/product_model";
import { useEffect, useState } from "react";
import routes from "../../../routes";
import cartFacade from "../facades/cart_facade";
import { Cart, Product } from "../models/cart_model";
import { ca } from "date-fns/locale";
import authFacade from "../../authentication/facades/auth_facade";
import Toast from "../../../utils/toast";

function CartPage() {
  const navigate = useNavigate();

  const { carts, fetchCart, deleteCart } = cartFacade();
  const { isAuthenticated } = authFacade();

  const [selected, setSelected] = useState<boolean[]>([]);
  const [selectedCarts, setSelectedCarts] = useState<Cart[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    fetchCart(isAuthenticated);
  }, []);

  const updateNewSelectedCarts = (newSelectedCarts?: Cart) => {
    if (newSelectedCarts) {
      setSelectedCarts(
        selectedCarts.map((cart) => {
          if (cart.product_id === newSelectedCarts.product_id) {
            return newSelectedCarts;
          }
          return cart;
        })
      );
    }
  };

  const updateTotalPrice = () => {
    fetchCart(isAuthenticated);
    let price = 0;

    selectedCarts.forEach((cart) => {
      if (cart?.product?.price && cart?.qty) {
        price += cart.product.price * cart.qty;
      }
    });
    setTotalPrice(price);
  };

  const handleDelete = async () => {
    if (isAuthenticated) {
      const cartIds = (selectedCarts.map((cart) => cart.id) as number[]) ?? [];
      if (cartIds.length > 0) {
        deleteCart(cartIds).then((res) => {
          if (res) {
            Toast.show("Berhasil menghapus keranjang", {});
            fetchCart(isAuthenticated);
          }
        });
      }
    } else {
      const productIds =
        (selectedCarts.map((cart) => cart.product_id) as number[]) ?? [];
      if (productIds.length > 0) {
        const newSelectedCart = carts?.data?.filter((cart) => {
          return !productIds.includes(cart?.product_id ?? 0);
        });
        setSelectedCarts(newSelectedCart ?? []);
        localStorage.setItem("unauthCarts", JSON.stringify(newSelectedCart));
      }
    }
  };

  useEffect(() => {
    updateTotalPrice();
  }, [selectedCarts]);

  return (
    <div className="cart-page">
      <div className="header">
        <img
          src={back}
          alt=""
          className="back-btn"
          onClick={() => navigate(-1)}
        />
        <div className="title">Keranjang</div>
      </div>
      <div className="content">
        {carts?.data?.length == 0 ? (
          <EmptyCart></EmptyCart>
        ) : (
          <>
            <div className="check-all">
              <div
                className={`check-box${
                  selected.every((value) => value) && selected.length != 0
                    ? " active"
                    : ""
                }`}
                onClick={() => {
                  const selectAll = new Array(selected.length);
                  selectAll.fill(!selected.every((value) => value));
                  setSelected(selectAll);
                  if (selected.every((value) => value)) {
                    setSelectedCarts([]);
                  } else {
                    setSelectedCarts(carts?.data ?? []);
                  }
                }}
              ></div>
              <p>
                Pilih Semua (
                {selected.reduce((count, currentValue) => {
                  if (currentValue === true) {
                    return count + 1;
                  }
                  return count;
                }, 0)}
                )
              </p>
              <div className="remove" onClick={handleDelete}>
                Hapus
              </div>
            </div>
            {carts?.data?.map((cart, i) => (
              <CartItem
                needWishlist={!isAuthenticated}
                key={i}
                selected={selected[i]}
                cart={cart}
                updateTotalProps={updateNewSelectedCarts}
                onSelectedChange={(val) => {
                  selected[i] = val;
                  setSelected([...selected]);
                  if (val) {
                    setSelectedCarts([...selectedCarts, cart]);
                  } else {
                    setSelectedCarts(
                      selectedCarts.filter((selectedCart) => {
                        return selectedCart.id !== cart.id;
                      })
                    );
                  }
                }}
              ></CartItem>
            ))}
          </>
        )}
      </div>
      <CartSummary
        onActionTap={() =>
          totalPrice > 0 &&
          navigate(routes.checkout, { state: { carts: selectedCarts } })
        }
        showVoucher={true}
        price={totalPrice}
      />
    </div>
  );
}

export default CartPage;
