import { useQuery } from '@tanstack/react-query';
import { apiClient } from '../../../../../api/api-client';

const getDivisionsFn = async () => {
    const response = await apiClient.get('/v1/careers/divisions');
    return response.data.data;
};

export function useDivisions() {
    return useQuery({
        queryKey: ['useDivisions'],
        queryFn: getDivisionsFn,
    });
}
