export default function History(props) {

    if (props.isLeft) {
        return (
            <div className={props.itemClass}>
                <img src={props.patternImg} alt="" className={props.patternClass} />
                <img src={props.img} alt="" />
                <div className="text-right">
                    <h2>
                        {props.title}
                    </h2>
                    <p>{props.desc}</p>
                </div>
            </div>
        )

    }

    return (
        <div className={props.itemClass}>
            <div className="text-left">
                <h2>
                    {props.title}
                </h2>
                <p>{props.desc}</p>
            </div>
            <img src={props.patternImg} alt="" className={props.patternClass} />
            <img src={props.img} alt="" />
        </div>
    )
}