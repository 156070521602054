import { ChangeEvent, useEffect, useRef, useState } from "react";
import emptyIcon from "../../../assets/images/empty_product.svg";
import backIcon from "../../../assets/images/back.svg";
import searchIcon from "../../../assets/images/ic_search.svg";
import menuFilter from "../../../assets/images/ic_filter.svg";
import menuCart from "../../../assets/images/ic_bag.svg";
import icTimer from "../../../assets/images/ic_timer.svg";
import product1 from "../../../assets/images/product-1.png";
import product2 from "../../../assets/images/product-2.png";
import dummyProduct1 from "../../../assets/images/dummy-product-1.png";
import dummyProduct2 from "../../../assets/images/dummy-product-2.png";
import dummyProduct3 from "../../../assets/images/dummy-product-3.png";
import BottomSheet from "../../shared/components/bottom_sheet";
import TextInput from "../../shared/components/text_input";
import { useNavigate, Link, useLocation } from "react-router-dom";
import routes from "../../../routes";
import Slider from "react-slick";
import display1 from "../../../assets/images/display1.png";
import display2 from "../../../assets/images/display2.png";
import display3 from "../../../assets/images/display3.png";
import adsBanner from "../../../assets/images/ads-banner.png";
import productFacade from "../../product/facade/product_facade";
import { ProductFilter } from "../../product/models/product_model";
import CartIcon from "../../global_component/view/cart_icon";

function SearchPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    products,
    productPopulars,
    lastSeen,
    fetchProducts,
    likeProduct,
    fetchLastSeen,
  } = productFacade();
  const [search, setSearch] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [sortFilter, setSortFilter] = useState("");
  const [open, setOpen] = useState(false);
  const [minPrice, setMinPrice] = useState("0");
  const [maxPrice, setMaxPrice] = useState("10000000");
  const [categoryId, setCategoryId] = useState(location.state?.categoryId ?? 0);

  const settings = {
    dots: true,
    autoplay: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleSearch = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setSearch(event.target.value);
  };

  const handleFilter = () => {
    setOpen(false);
    fetchProducts({
      keyword: search,
      sorter: sortFilter,
      minPrice: parseInt(minPrice),
      maxPrice: parseInt(maxPrice),
      categoryId: categoryId,
    });
  };

  const handleLike = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    productId: number
  ) => {
    event.stopPropagation();
    await likeProduct(productId);
    fetchProducts({
      keyword: search,
      sorter: sortFilter,
      minPrice: parseInt(minPrice),
      maxPrice: parseInt(maxPrice),
      categoryId: categoryId,
    });
  };

  useEffect(() => {
    fetchProducts({
      keyword: search,
      sorter: sortFilter,
      minPrice: parseInt(minPrice),
      maxPrice: parseInt(maxPrice),
      categoryId: categoryId,
    });
  }, [search]);

  useEffect(() => {
    fetchLastSeen();
    fetchProducts({
      categoryId: categoryId,
      keyword: "",
      maxPrice: 1000000000,
      minPrice: 0,
      isPopular: true,
    });
  }, []);

  return (
    <div id="base-root" className="dashboard">
      <div className="header">
        <div className={"search" + (isFocused ? " focused" : "")}>
          <img
            src={backIcon}
            alt=""
            className="back"
            onClick={() => navigate(-1)}
          />
          <input
            type="text"
            placeholder="Cari Produk di MamaBear"
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleSearch}
            value={search}
          />
          <img src={searchIcon} alt="" className="action" />
        </div>
        <div className="actions">
          <CartIcon />
          <img
            src={menuFilter}
            onClick={() => setOpen(true)}
            className="menu"
          />
        </div>
        {/* <div className="recent-search">
          <p>
            <img src={icTimer} alt="" />
            MamaBear soya lemon mix alpukat
          </p>
          <p>
            <img src={icTimer} alt="" />
            MamaBear soya pelancar asi bayi
          </p>
          <p>
            <img src={icTimer} alt="" />
            MamaBear soya asi booster
          </p>
        </div> */}
        <div className="recent-viewed">
          <h6>Terakhir Dilihat</h6>
          <div className="items">
            {lastSeen.map((product) => (
              <div className="item">
                <figure>
                  <img src={product.image_url_text} />
                </figure>
                <p>{product.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="recent-popular">
          <h6>Paling Populer</h6>
          <div className="popular">
            {productPopulars?.data?.slice(0, 5).map((data, index) => (
              <span
                onClick={function () {
                  navigate(routes.productDetails, { state: data });
                }}
              >
                {data.name}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="contents-wrapper">
        <div className="contents">
          {products.data?.length == 0 ? (
            <div className="empty">
              <img src={emptyIcon} />
              <h5>Produk Tidak Ditemukan</h5>
              <p>
                Coba sesuaikan kata kunci
                <br />
                untuk menemukan apa yang anda cari
              </p>
            </div>
          ) : (
            <>
              <div className="title">Product</div>
              <div className="grid">
                {products.data?.map((data, index) => {
                  return (
                    <div
                      className="items"
                      onClick={() => {
                        navigate(routes.productDetails, { state: data });
                      }}
                    >
                      <figure>
                        <img
                          src={data.image_url_text}
                          onError={(
                            e: React.SyntheticEvent<HTMLImageElement, Event>
                          ) => {
                            e.currentTarget.src = dummyProduct1;
                          }}
                        />
                        <div
                          className={data.user_liked_product ? "liked" : ""}
                          onClick={(
                            event: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => handleLike(event, data.id ?? 0)}
                        ></div>
                        <span className="cart"></span>
                      </figure>
                      <div className="desc">
                        <div className="title">
                          {data.name ?? "Product Name"}
                        </div>
                        <div className="price">
                          <span>{data.price_text}</span>
                        </div>
                        <div className="row crossAxisCenter">
                          {/* <span className="discount">50%</span>
                        <div className="original-price">Rp 150.000</div> */}
                          <div className="rating">({data.rating})</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <BottomSheet
          open={open}
          onClose={() => setOpen(false)}
          showAction={true}
        >
          <div className="sort-filter">
            <h6>Paling Populer</h6>
            <div className="sort-by">
              {Object.values(ProductFilter).map((filter) => (
                <span
                  key={filter.value}
                  className={sortFilter === filter.value ? "active" : ""}
                  onClick={() => setSortFilter(filter.value)}
                >
                  {filter.text}
                </span>
              ))}
            </div>
          </div>
          <div className="height-15"></div>
          <div className="row">
            <div className="expanded">
              <TextInput
                isOutlined={true}
                isObsecured={false}
                label={"Harga (Min)"}
                placeholder={"Rp"}
                value={minPrice}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
                  >
                ) => {
                  setMinPrice(event.target.value);
                }}
              />
            </div>
            <div className="width-10"></div>
            <div className="expanded">
              <TextInput
                isOutlined={true}
                isObsecured={false}
                label={"Harga (Maks)"}
                placeholder={"Rp"}
                value={maxPrice}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
                  >
                ) => {
                  setMaxPrice(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="height-15"></div>
          <div className="primary-button" onClick={handleFilter}>
            Tampilkan
          </div>
        </BottomSheet>
      </div>
    </div>
  );
}

export default SearchPage;
