import { useEffect } from "react";
import AccordionCustom from "../components/accordion";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import TitleHeader from "../components/title-header";
import { useFaqs } from "./homepage/queries/use-faq";

export default function Faq() {
  const faqs = useFaqs();

  // console.log(faqs.data);

  return (
    <div className="wrapper-pages">
      <Navbar />
      <TitleHeader
        title={"Frequently Asked Questions"}
        bread={
          <div
            dangerouslySetInnerHTML={{ __html: "Bantuan / <span>FAQ</span>" }}
          />
        }
      />

      {faqs.isLoading && (
        <div className="py-2 text-teal-900 font-bold">Loading...</div>
      )}

      {faqs.isFetching && (
        <div className="py-2 text-teal-900 font-bold">Fetching...</div>
      )}

      {faqs.error instanceof Error && <div>{faqs.error.message}</div>}

      <div className="container">
        <div className="faq-pages">
          {faqs.isSuccess &&
            faqs.data.map((faq, index) => (
              <div className="item" style={{ marginTop: "40px" }}>
                <h1>{faq.name}</h1>
                <div className="accordion" id="accordionExample">
                  {faq.faqs.map((item) => (
                    <>
                      <AccordionCustom
                        desc={item.text}
                        title={item.subtitle}
                        target={"target2"}
                        show={true}
                        collapsed={true}
                      />
                    </>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
