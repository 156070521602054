import BackButton from '../components/back_button';
import VerificationOptions from '../components/verification_options';
import { useEffect, useState } from 'react';
import emailLogo from '../../../assets/images/email.svg';
import whatsappLogo from '../../../assets/images/mobile.svg';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import authFacade from '../facades/auth_facade';

function VerificationMethodScreen() {
    const [queryParameters] = useSearchParams()
    const navigate = useNavigate()
    const [activeOption, setActiveOption] = useState(0);
    const location = useLocation();
    const { email, phone, phone_country_code } = authFacade();


    useEffect(
        function () {
            // console.log(email)
            // console.log(phone)
            // console.log(location.state)
            if (email.trim() == "") {
                setActiveOption(1)
            }
        }, []
    )

    return (
        <div id="auth-root">
            <div className="height-50"></div>
            <div className="height-20"></div>
            <BackButton />
            <div className="height-18"></div>
            <div className="header">
                <h1>
                    Metode Verifikasi
                </h1>
                <p>
                    Pilih salah satu metode dibawah ini untuk mendapatkan kode verifikasi
                </p>
            </div>
            <div className="height-50"></div>
            <VerificationOptions onTap={function () {
                if (email.trim() != "") {
                    setActiveOption(0);
                }
            }} icon={emailLogo} isActive={activeOption == 0} title='Email ke' subtitle={email} />
            <div className="height-22"></div>
            <VerificationOptions onTap={function () {
                if (`${phone_country_code}${phone}`.trim() != "") {
                    setActiveOption(1);
                }
            }} icon={whatsappLogo} isActive={activeOption == 1} title='WhatsApp ke' subtitle={`${phone_country_code}${phone}`} />
            <div className="height-50"></div>
            <div className="primary-button" onClick={() => navigate(`/store/otp${queryParameters.get("register") != null ? '?register=true' : ''}`, { state: { type: activeOption == 0 ? "email" : "phone" } })}>
                Konfirmasi
            </div>

        </div>
    );
}
export default VerificationMethodScreen;