export default function TitleHeader(props) {
  return (
    <div className="title-header">
      <div className="wrapper">
        <h1>{props.title}</h1>
        <h2>
          {props.bread}
        </h2>
      </div>
    </div>
  );
}
