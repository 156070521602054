import { useNavigate } from "react-router-dom";
import cart from "../../../assets/images/bag.svg";
import routes from "../../../routes";
import cartFacade from "../../order/facades/cart_facade";

function CartIcon() {
  const navigate = useNavigate();

  const { carts } = cartFacade();

  return (
    <div className="cart-btn" onClick={() => navigate(routes.cart)}>
      <img src={cart} alt="" />
      <span>{carts?.total ?? 0}</span>
    </div>
  );
}

export default CartIcon;
