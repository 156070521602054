import { shallow } from "zustand/shallow"
import paymentMethodStore from "../store/payment_method_store"

const PaymentMethodFacade = () => {

    const {paymentMethods, fetchPaymentMethod} = paymentMethodStore(
        (state) => ({
            paymentMethods: state.paymentMethods,
            fetchPaymentMethod: state.fetchPaymentMethod,
        }),
        shallow
    )

    return {paymentMethods, fetchPaymentMethod}

}

export default PaymentMethodFacade;